<section class="content">
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header">
                        <h2><strong>Комментарии к ОПС</strong></h2>
                        <ul class="rso-comments-header-container">
                            <li class="checkbox-container">
                                <input
                                    type="checkbox"
                                    class="checkbox-rso-comments"
                                    (change)="setShowOnlyCreated()"
                                >
                                <p style="margin: 0">
                                    Показать только необработанные
                                </p>
                            </li>
                            <li class="checkbox-container">
                                <input
                                    type="checkbox"
                                    class="checkbox-rso-comments"
                                    (change)="setShowRejected()"
                                >
                                <p style="margin: 0">
                                    Показать отклоненные
                                </p>
                            </li>
                            <li class="rso-search-container">
                                <input
                                    matInput
                                    [(ngModel)]="searchValue"
                                    placeholder="Поиск..."
                                    class="rso-search-input"
                                >
                                <button
                                    class="rso-remove-search-btn"
                                    (click)="removeSearchValue()"
                                >
                                    <i-feather
                                            name="x"
                                    ></i-feather>
                                </button>
                                <button
                                    class="rso-search-btn-container"
                                    (click)="applyFilter()"
                                >
                                    <i-feather
                                            name="search"
                                            class="sidebarIcon"
                                    ></i-feather>
                                    <p style="margin: 0; font-weight: 600">
                                        Поиск
                                    </p>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="dataSource" class="w-100">
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef>ID коммнетария</th>
                                <td mat-cell *matCellDef="let element">
                                    #{{ element.id }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="userName">
                                <th mat-header-cell *matHeaderCellDef>Название ОПС</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.rsoName }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="rsoRowId">
                                <th mat-header-cell *matHeaderCellDef>ID ОПС</th>
                                <td mat-cell *matCellDef="let element">
                                    #{{ element.rsoRowId }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="rate">
                                <th mat-header-cell *matHeaderCellDef>Средний рейтинг ОПС</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.rate }} / 5
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="comment">
                                <th mat-header-cell *matHeaderCellDef>Текст комментария</th>
                                <td mat-cell *matCellDef="let element" class="w-25">
                                    {{ element.comment }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>Действия</th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    style="text-align: center"
                                    class="pr-0"
                                >
                                    <div
                                        *ngIf="element.status === commentStatuses.CREATED"
                                        style="display: flex; flex-direction: column; align-items: flex-start"
                                    >
                                        <button
                                            class="btn-tbl-confirm-secondary"
                                            (click)="$event.stopPropagation()"
                                            (click)="confirmRsoComment(element.id)"
                                        >
                                            Подтвердить
                                        </button>
                                        <button
                                            class="btn-tbl-reject-secondary"
                                            (click)="$event.stopPropagation()"
                                            (click)="rejectRsoComment(element.id)"
                                        >
                                            Отклонить
                                        </button>
                                    </div>
                                    <div *ngIf="element.status === commentStatuses.ACTIVE" style="display: flex">
                                        <button
                                            class="btn-tbl-reject-secondary"
                                            (click)="$event.stopPropagation()"
                                            (click)="rejectRsoComment(element.id)"
                                        >
                                            Снять с публикации
                                        </button>
                                    </div>
                                    <div *ngIf="element.status === commentStatuses.REJECTED" style="display: flex">
                                        <button
                                            class="btn-tbl-in-progress-secondary"
                                            (click)="$event.stopPropagation()"
                                            (click)="confirmRsoComment(element.id)"
                                        >
                                            Опубликовать
                                        </button>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns"
                            ></tr>
                        </table>
                        <mat-paginator
                            #paginator
                            [length]="totalElements"
                            [pageSize]="pageSize"
                            [pageIndex]="pageIndex"
                            (page)="handlePageEvent($event)"
                            hidePageSize
                            showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
