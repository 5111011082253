export const INVALID = 'INVALID';

/**
 * DICTIONARY
 */
export const PRODUCT_TYPE = 'PRODUCT_TYPE'; // тип продукта
export const FAMILY = 'FAMILY'; // семейство
export const MODEL = 'MODEL'; // модель
export const MEMORY = 'MEMORY'; // память
export const COLOR = 'COLOR'; // цвет
export const CURRENCY = 'CURRENCY'; // валюта
export const RAM = 'RAM'; // ОЗУ

/**
 * PERSON RESOURCE
 */
export const USER = 'USER'; // Продавец
export const CLIENT = 'CLIENT'; // Клиет
export const PROVIDER = 'PROVIDER'; // Поставщик

export const YES = 'YES'; // есть в наличий
export const SOLD = 'SOLD'; // продано
export const BRON = 'BRON'; // бронь
export const NEW = 'NEW'; // новая
export const OLD = 'OLD'; // б/у

export const SEND = 'SEND'; // Перевод на общую кассу
export const WITHDRAW = 'WITHDRAW'; // Снять кассу
export const DEBTSTORE = 'DEBTSTORE'; // В долг другим магазинам
export const DEBTCLIENT = 'DEBTCLIENT'; // В долг клиентам
export const ADDCASH = 'ADDCASH'; // Добавить баланс в магазин

/**
 * режимы формы
 */
export const enum FormMode {
  View = 0,
  Edit = 1,
  New = 2,
  Empty = 3,
}

export class Pagination {
  public static readonly YES: number = 1;
  public static readonly NO: number = 0;
  public static readonly DEFAULT_PAGE_SIZE: number = 50;
  public static readonly DEFAULT_PAGE: number = 0;
}

export const ADD_PRODUCT = 'add_product'; // admin/warehouse/add-product
export const WAREHOUSE_SEARCH = 'search_product'; // admin/warehouse/warehouse-search
export const SALE = 'sale_product'; // admin/sale
export const PRODUCT = 'product'; // admin/product
export const INTERCEPTOR = 'tokenInterceptor';

/**
 * ROlES
 */

export const SUPER_ADMIN = 'SUPER_ADMIN';
export const ADMIN = 'ADMIN';
export const SELLER = 'SELLER';
export enum RoleTypes {
  ADMIN = 'ROLE_ADMIN',
  EMPLOYEE = 'ROLE_EMPLOYEE',
  SUPERADMIN = 'ROLE_SUPERADMIN',
  ROD_SUPERVISOR = 'ROLE_ROD_SUPERVISOR',
  ROD_SPECIALIST = 'ROLE_ROD_SPECIALIST',
  ROD_OPERATOR = 'ROLE_ROD_OPERATOR',
  RSO_SPECIALIST = 'ROLE_RSO_SPECIALIST',
  SPECIALIST = 'ROLE_SPECIALIST',
  USER = 'ROLE_USER',
  LAW = 'ROLE_LAW',
  ROLE_SUPERVISOR = 'ROLE_SUPERVISOR',
}
export const RoleNames = {
  [RoleTypes.ADMIN]: 'Администратор приложения',
  [RoleTypes.EMPLOYEE]: 'Сотрудник',
  [RoleTypes.SUPERADMIN]: 'Суперадминистратор приложения',
  [RoleTypes.ROD_SUPERVISOR]: 'Руководитель ДЭУ',
  [RoleTypes.ROD_SPECIALIST]: 'Специалист ДЭУ',
  [RoleTypes.ROD_OPERATOR]: 'Оператор ДЭУ',
  [RoleTypes.RSO_SPECIALIST]: 'Специалист ОПС',
  [RoleTypes.SPECIALIST]: 'Специалист',
  [RoleTypes.USER]: 'Пользователь',
  [RoleTypes.ROLE_SUPERVISOR]: 'Руководитель',
  [RoleTypes.LAW]: '',
};
export const RolesWithRegion = [RoleTypes.ROD_OPERATOR, RoleTypes.ROD_SPECIALIST, RoleTypes.ROD_SUPERVISOR];

/**
 * START GEO CONSTANTS
 */


export const ROLE_USER = {
  name: 'Пользователь',
  code: 'ROLE_USER'
};
export const ROLE_ADMIN = {
  name: 'Админ',
  code: 'ROLE_ADMIN'
};
export const ROLE_EMPLOYEE = {
  name: 'Сотрудник',
  code: 'ROLE_EMPLOYEE',
};
export const ROLE_RSO_SPECIALIST = {
  name: 'Сотрудник ОПС',
  code: 'ROLE_RSO_SPECIALIST',
};

export const READ = 'READ';
export const WRITE = 'WRITE';

export const commentStatuses = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
};

export const gisitsApi = 'http://192.168.122.132:4207';
