import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { ApiConfigUsers } from 'src/api.config';
import { User } from 'src/app/model/user';
import { AuthService } from '../../authentication/service/auth.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    if (route.queryParams.access_token) {
      this.authService.setAuthData(route.queryParams.access_token)
    }

    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      this.spinner.show();
      return this.http.get(`${ApiConfigUsers.api}/user/current`).pipe(
        map((user: User) => {
          if (user) {
            this.authService.user.setValue(user);
            this.spinner.hide();
            return true;
          }
          this.spinner.hide();
          return false;
        })
      );
    } else {
      this.router.navigate(['/signin']);
      this.spinner.hide();
      return of(false);
    }
  }
}
