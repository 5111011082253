import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { MaterialModule } from './material.module';
import { FeatherIconsModule } from './feather-icons.module';
import { NgxMaskModule } from 'ngx-mask';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TreeModule } from '@circlon/angular-tree-component';

@NgModule({
  declarations: [SpinnerComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    FeatherIconsModule,
    MaterialModule,
    TreeModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    FeatherIconsModule,
    NgxMaskModule,
    TreeModule,
    SpinnerComponent,
  ],
})
export class SharedModule { }
