import { Injectable } from '@angular/core';
import {RegionType} from "../../components/events/models/events.model";
import {NotificationService} from "./notification.service";
import {AdminService} from "../../services/admin.service";
import {KatoKazakhstan} from "../../components/events-report/models/events-report.model";

@Injectable({
  providedIn: 'root'
})
export class KatoService {
  katoList: RegionType[] = [];

  constructor(
      private ntf: NotificationService,
      private adminService: AdminService,
  ) { }

  getKatoList() {
    this.ntf.showLoader();
    this.adminService.getKatoList().subscribe({
      next: (kato: RegionType[]) => {
        this.katoList = kato.filter(item => item.code !== KatoKazakhstan);
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  getKatoItem(katoCode: string, withoutCode?: boolean) {
    const currentKato: RegionType = this.katoList?.find(kato => kato.code === katoCode);
    return withoutCode ? currentKato?.nameRu: `${currentKato?.code} (${currentKato?.nameRu})`;
  }
}
