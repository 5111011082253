import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tariff-management',
  templateUrl: './tariff-management.component.html',
  styleUrls: ['./tariff-management.component.sass']
})
export class TariffManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
