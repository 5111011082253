<section class="content">
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="header">
            <h2>
              <strong>Журнал логирования</strong>
              <small>Компонент для просмотра действий пользователей</small>
            </h2>
          </div>
          <div class="body">
            <form [formGroup]="form">
              <div class="row">
                <div class="col-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Выберите пользователя</mat-label>
                    <mat-select formControlName="userId">
                      <mat-option>--</mat-option>
                      <mat-option *ngFor="let user of users" [value]="user.id">
                        {{ user.lastName + ' ' + user.firstName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Выберите тип действия</mat-label>
                    <mat-select formControlName="operation">
                      <mat-option>--</mat-option>
                      <mat-option
                        *ngFor="let operation of operations"
                        [value]="operation.code"
                      >
                        {{ operation.nameRu }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Выберите слой</mat-label>
                    <mat-select formControlName="layerId">
                      <mat-option>--</mat-option>
                      <mat-option
                        *ngFor="let layer of layers"
                        [value]="layer.id"
                      >
                        {{ layer.nameRu }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field style="width: 100%">
                    <mat-label>Идентификация объекта</mat-label>
                    <input
                      matInput
                      type="number"
                      autocomplete="off"
                      formControlName="objectId"
                    />
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Дата события с:</mat-label>
                    <input
                      matInput
                      [matDatepicker]="operationDateFrom"
                      formControlName="operationDateFrom"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="operationDateFrom"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #operationDateFrom></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Дата события до:</mat-label>
                    <input
                      matInput
                      [matDatepicker]="operationDateTo"
                      formControlName="operationDateTo"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="operationDateTo"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #operationDateTo></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <button
                    class="example-full-width"
                    type="button"
                    mat-stroked-button
                    color="warn"
                    (click)="clear()"
                  >
                    Очистить
                  </button>
                </div>
                <div class="col-6">
                  <button
                    class="example-full-width"
                    type="submit"
                    mat-stroked-button
                    color="primary"
                    (click)="search()"
                  >
                    Поиск
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row clearfix">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="body table-responsive">
            <mat-paginator
              [length]="totalCount"
              [pageSizeOptions]="[50, 100, 200]"
              (page)="search($event)"
            >
            </mat-paginator>

            <table mat-table [dataSource]="dataSource" class="w-100" matSort>
              <ng-container matColumnDef="position">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  style="width: 30px"
                >
                  №
                </th>
                <td mat-cell *matCellDef="let element; let idx = index">
                  <span>{{ idx + 1 }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="userInfo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Пользователь
                </th>
                <td mat-cell *matCellDef="let element; let idx = index">
                  <span>{{
                    element.userInfo.lastName + ' ' + element.userInfo.firstName
                  }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="operationDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Дата</th>
                <td mat-cell *matCellDef="let element; let idx = index">
                  <span>{{
                    element.operationDate | date: 'dd.MM.yyyy HH:mm'
                  }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="operation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Операция
                </th>
                <td mat-cell *matCellDef="let element; let idx = index">
                  <span>{{ element.operation }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="layerInfo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Слой</th>
                <td mat-cell *matCellDef="let element; let idx = index">
                  <span>{{ element.layerInfo.nameRu }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="objectId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Ид. объекта
                </th>
                <td mat-cell *matCellDef="let element; let idx = index">
                  <span>{{ element.objectId | number }}</span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
