import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {NotificationService} from "../../shared/services/notification.service";
import {AdminService} from "../../services/admin.service";
import {
  GetNotificationsResponse,
  NotificationResponse,
  NotificationTypes,
  NotificationTypesFilter,
} from "./model/notifications.model";
import {MatDialog} from "@angular/material/dialog";
import {NotificationCreateComponent} from "./notification-create/notification-create.component";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass']
})
export class NotificationsComponent implements OnInit {
  displayedColumns: string[] = [
      'id',
      'createdDate',
      'messageRu',
      'licensePlates',
  ];

  dataSource = new MatTableDataSource<NotificationResponse>();
  notificationTypes = NotificationTypesFilter;
  topicType = 'push';
  filterByType = NotificationTypes.COMMON;
  pageSize = 10;
  pageIndex = 0;
  totalElements = null;
  pageEvent: PageEvent;

  constructor(
      private ntf: NotificationService,
      private adminService: AdminService,
      public dialog: MatDialog,
  ) {
  }

  @ViewChild('paginator') paginator: MatPaginator;
  @Output() page: EventEmitter<PageEvent>;

  ngOnInit() {
    this.getNotifications();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalElements = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getNotifications();
  }

  onSelectType(type: NotificationTypes) {
    this.pageIndex = 0;
    this.filterByType = type;
    this.getNotifications();
  }

  openCreateNotificationModal() {
    let dialogRef = this.dialog.open(NotificationCreateComponent);
    dialogRef.afterClosed().subscribe(_ => {
      this.getNotifications()
    });
  }

  getNotifications() {
    this.ntf.showLoader();
    this.adminService.getNotifications(this.topicType, this.filterByType, this.pageIndex, this.pageSize).subscribe({
      next: (data: GetNotificationsResponse) => {
        const {content, totalElements} = data;
        this.totalElements = totalElements;
        this.dataSource = new MatTableDataSource(content);
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  getDate(date: string) {
    const newDate = new Date(date);
    function setZeroToString(num: number) {
      if (String(num).length === 1) {
        return `0${num}`;
      } else {
        return num;
      }
    }
    return `
      ${setZeroToString(newDate.getDay())}.${setZeroToString(newDate.getMonth() + 1)}.${newDate.getFullYear()} 
      ${setZeroToString(newDate.getHours())}:${setZeroToString(newDate.getMinutes())}
     `;
  }
}
