<div *ngIf="ntf.isLoading" class="preloader pl-size-xs custom_loader">
  <div class="spinner-layer pl-purple">
    <div class="circle-clipper left">
      <div class="circle"></div>
    </div>
    <div class="circle-clipper right">
      <div class="circle"></div>
    </div>
  </div>
</div>
