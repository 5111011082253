<section class="content">
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header">
                        <h2><strong>Анализ по закрытия и открытию дорог</strong></h2>
                        <ul class="rso-comments-header-container">
                            <li
                                class="filter-container"
                            >
                                <p style="margin: 0">
                                    Фильтровать по периоду
                                </p>
                                <div class="events-report__filter-date-container">
                                    <div class="events-report__filter-date">
                                        <p style="margin: 0">
                                            Дата "с"
                                        </p>
                                        <input
                                            class="filter__select"
                                            type="date"
                                            [valueAsDate]="filterByDateFrom"
                                            (change)="onSelectDate($event.target.valueAsDate, true)"
                                        />
                                    </div>
                                    <div class="events-report__filter-date">
                                        <p style="margin: 0">
                                            Дата "по"
                                        </p>
                                        <input
                                            class="filter__select"
                                            type="date"
                                            [valueAsDate]="filterByDateTo"
                                            (change)="onSelectDate($event.target.valueAsDate, false)"
                                        />
                                    </div>
                                </div>
                                <p style="margin: 0">
                                    Фильтровать по области
                                </p>
                                <select
                                        [(ngModel)]="filterByKato"
                                        class="filter__select"
                                        (ngModelChange)="onSelectKato($event)"
                                >
                                    <option
                                            selected
                                            disabled
                                            [value]="notSelectedText"
                                            class="filter__option"
                                    >
                                        {{ notSelectedText }}
                                    </option>
                                    <option
                                            *ngFor="let kato of kato.katoList"
                                            [value]="kato.code"
                                            class="filter__option"
                                    >
                                        {{ kato.nameRu }}
                                    </option>
                                </select>
                                <button
                                    class="btn-tbl-reject-secondary"
                                    (click)="removeChartFilterValues()"
                                >
                                    <p style="margin: 0; font-weight: 600">
                                        Сброс
                                    </p>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="body events-report__charts-container">
                        <div class="events-report__chart">
                            <canvas baseChart #closeRoadChart="base-chart"
                                [datasets]="closeRoadChartDataSets"
                                [labels]="closeRoadChartLabels"
                                [options]="closeRoadChartOptions"
                                [chartType]="closeRoadChartType"
                            ></canvas>
                        </div>
                    </div>
                    <div class="header events-report__pagination-container">
                        <button
                            class="events-report__pagination-button"
                            [ngClass]="{
                                'events-report__pagination-button-disabled':
                                closeRoadDataPageIndex === 1
                            }"
                            [disabled]="closeRoadDataPageIndex === 1"
                            (click)="paginationHandler(paginationWayTypes.START)"
                        >
                            <i-feather
                                name="chevrons-left"
                            ></i-feather>
                        </button>
                        <button
                            class="events-report__pagination-button"
                            [ngClass]="{
                                'events-report__pagination-button-disabled':
                                closeRoadDataPageIndex === 1
                            }"
                            [disabled]="closeRoadDataPageIndex === 1"
                            (click)="paginationHandler(paginationWayTypes.PREV)"
                        >
                            <i-feather
                                name="chevron-left"
                            ></i-feather>
                        </button>
                        <p style="margin: 0 5px;">
                            {{ closeRoadDataPageIndex }} из {{ closeRoadDataLastPageIndex }}
                        </p>
                        <button
                                class="events-report__pagination-button"
                                [ngClass]="{
                                    'events-report__pagination-button-disabled':
                                    closeRoadDataPageIndex === closeRoadDataLastPageIndex
                                }"
                                [disabled]="closeRoadDataPageIndex === closeRoadDataLastPageIndex"
                                (click)="paginationHandler(paginationWayTypes.NEXT)"
                        >
                            <i-feather
                                name="chevron-right"
                            ></i-feather>
                        </button>
                        <button
                            class="events-report__pagination-button"
                            [ngClass]="{
                                'events-report__pagination-button-disabled':
                                closeRoadDataPageIndex === closeRoadDataLastPageIndex
                            }"
                            [disabled]="closeRoadDataPageIndex === closeRoadDataLastPageIndex"
                            (click)="paginationHandler(paginationWayTypes.LAST)"
                        >
                            <i-feather
                                name="chevrons-right"
                            ></i-feather>
                        </button>
                    </div>
                </div>
                <div class="card">
                    <div class="header">
                        <h2><strong>Таблица "Закрытие и открытие дорог"</strong></h2>
                        <ul class="rso-comments-header-container">
                            <li class="rso-search-container">
                                <input
                                    matInput
                                    [(ngModel)]="searchRoadValue"
                                    placeholder="Поиск..."
                                    class="rso-search-input"
                                >
                                <button
                                    class="rso-remove-search-btn"
                                    (click)="removeSearchRoadValue()"
                                >
                                    <i-feather
                                            name="x"
                                    ></i-feather>
                                </button>
                                <button
                                    class="rso-search-btn-container"
                                    (click)="applySearchRoadValue()"
                                >
                                    <i-feather
                                            name="search"
                                            class="sidebarIcon"
                                    ></i-feather>
                                    <p style="margin: 0; font-weight: 600">
                                        Поиск
                                    </p>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="closeRoadDataSource" class="w-100">
                            <ng-container matColumnDef="gid">
                                <th mat-header-cell *matHeaderCellDef>id записи</th>
                                <td mat-cell *matCellDef="let element" style="width: 40px;">
                                    {{ element.gid }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="name_ru">
                                <th mat-header-cell *matHeaderCellDef>Дорога</th>
                                <td mat-cell *matCellDef="let element" style="width: 400px">
                                    {{ element.name_ru }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="user_name">
                                <th mat-header-cell *matHeaderCellDef>ФИО ответственного</th>
                                <td mat-cell *matCellDef="let element" style="width: 200px">
                                    {{ element.user_name }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="end_date">
                                <th mat-header-cell *matHeaderCellDef>Дата и время последнего закрытия</th>
                                <td mat-cell *matCellDef="let element" style="width: 200px">
                                    {{ getDateFormat(element.end_date) }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="start_date">
                                <th mat-header-cell *matHeaderCellDef>Дата и время последнего открытия</th>
                                <td mat-cell *matCellDef="let element" style="width: 200px">
                                    {{ getDateFormat(element.start_date) }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="closed">
                                <th mat-header-cell *matHeaderCellDef>Текущий статус</th>
                                <td mat-cell *matCellDef="let element">
                                    <button
                                        *ngIf="element.closed"
                                        class="btn-tbl-reject-secondary"
                                    >
                                        <p style="margin: 0; font-weight: 600">
                                            Закрыто
                                        </p>
                                    </button>
                                    <button
                                        *ngIf="!element.closed"
                                        class="btn-tbl-confirm-secondary"
                                    >
                                        <p style="margin: 0; font-weight: 600">
                                            Открыто
                                        </p>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="closeRoadDisplayedColumns"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: closeRoadDisplayedColumns"
                            ></tr>
                        </table>
                        <mat-paginator
                            #paginator
                            [length]="totalElements"
                            [pageSize]="pageSize"
                            [pageIndex]="pageIndex"
                            (page)="handlePageEvent($event)"
                            hidePageSize
                            showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
