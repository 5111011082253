import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.sass']
})
export class UserItemComponent implements OnInit, OnDestroy {
  $paramsSubscription: Subscription;

  user: any;
  // routeSubscription: Subscription

  constructor(private route: ActivatedRoute) {
    // this.routeSubscription = route.params.subscribe(params => this.id = params['id']);

    this.$paramsSubscription = this.route.queryParams.subscribe(params => {
      this.user = params;
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.$paramsSubscription.unsubscribe();
  }

}
