<div class="container-fluid">
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="card">
        <div class="header">
          <h2 *ngIf="userForm.id" class="font-bold col-cyan">
            Изменение пользователя
          </h2>
          <h2 *ngIf="!userForm.id" class="font-bold col-cyan">
            Добавление пользователя
          </h2>
        </div>
        <div class="body">
          <div class="row" *ngIf="userForm.isEnabled === 'false'">
            <div class="col-12">
              <div class="alert alert-danger">Пользователь заблокирован</div>
            </div>
          </div>
          <form class="dialog_form" [formGroup]="form">
            <div class="row">
              <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label> Фамилия </mat-label>
                  <input
                    matInput
                    autocomplete="off"
                    formControlName="lastName"
                    required
                    appearance="outline"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label> Имя </mat-label>
                  <input
                    matInput
                    autocomplete="off"
                    formControlName="firstName"
                    required
                  />
                </mat-form-field>
              </div>
            </div>


            <div class="col-12">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label> КАТО </mat-label>
                <mat-select
                    formControlName="kato"
                    [compareWith]="compareCategoryObjects"
                >
                  <mat-option *ngFor="let kato of kato.katoList" [value]="kato.code">
                    {{ kato.nameRu }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="row">
              <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label> Роль </mat-label>
                  <mat-select
                    required
                    formControlName="authorities"
                    [compareWith]="compareCategoryObjects"
                    (selectionChange)="selectionRole($event)"
                  >
                    <mat-option *ngFor="let role of roles" [value]="role">
                      {{ getRoleName(role) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label> E-mail </mat-label>
                  <input
                    matInput
                    formControlName="email"
                    autocomplete="off"
                    required
                  />
                  <mat-error
                    *ngIf="
                      form.controls.email.invalid && form.controls.email.touched
                    "
                  >
                    <span *ngIf="form.controls.email.errors?.required">
                      Email не может быть пустым
                    </span>
                    <span *ngIf="form.controls.email.errors?.email">
                      Введите корректный email
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label> Функциональный роль </mat-label>
                  <mat-select
                    #mySelect1
                    required
                    formControlName="functionRole"
                    [compareWith]="compareCategoryObjects"
                    (selectionChange)="toDetailFuncRole($event)"
                  >
                    <mat-option>--</mat-option>
                    <mat-option
                      *ngFor="let fRole of functionRoles"
                      [value]="fRole"
                    >
                      <span>
                        {{ fRole.code }}
                      </span>
                      <a class="dic-icon icon_delete">
                        <i
                          class="fas fa-trash-alt"
                          matTooltip="Удалить"
                          (click)="deleteFuncRole(fRole)"
                        ></i>
                      </a>
                    </mat-option>
                    <footer class="option-btn">
                      <button
                        mat-raised-button
                        class="btn-select btn-primary"
                        (click)="openDialogAddFuncRole(addDic)"
                      >
                        Добавить
                      </button>
                    </footer>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label> Роль по доступу к слоям </mat-label>
                  <mat-select
                    #mySelect2
                    required
                    formControlName="layerRole"
                    [compareWith]="compareCategoryObjects"
                    (selectionChange)="toDetailLayerRole($event)"
                  >
                    <mat-option>--</mat-option>
                    <mat-option
                      *ngFor="let lRole of layerRoles"
                      [value]="lRole"
                    >
                      {{ lRole.code }}
                      <a class="dic-icon icon_delete">
                        <i
                          class="fas fa-trash-alt"
                          matTooltip="Удалить"
                          (click)="deleteLayerRole(lRole)"
                        ></i>
                      </a>
                    </mat-option>
                    <footer class="option-btn">
                      <button
                        mat-raised-button
                        class="btn-select btn-primary"
                        (click)="openDialogAddLayerRole(addLayer)"
                      >
                        Добавить
                      </button>
                    </footer>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row" *ngIf="mode === 2">
              <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label> Пароль </mat-label>
                  <input
                    matInput
                    type="password"
                    autocomplete="off"
                    required
                    formControlName="password"
                  />
                  <mat-error
                    *ngIf="
                      form.controls.password.touched &&
                      form.controls.password.invalid
                    "
                    class="validation"
                  >
                    <span *ngIf="form.controls.password.errors?.required">
                      Введите пароль
                    </span>
                    <span *ngIf="form.controls.password.errors?.pattern">
                      Введите только латинские буквы и цифры
                    </span>
                    <span *ngIf="form.controls.password.errors?.minlength">
                      Пароль должен быть не менее
                      {{
                        form.controls.password.errors?.minlength.requiredLength
                      }}
                      символов. Сейчас он
                      {{
                        form.controls.password.errors?.minlength.actualLength
                      }}.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row" *ngIf="mode === 2">
              <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label> Подтверждение </mat-label>
                  <input
                    matInput
                    type="password"
                    autocomplete="off"
                    required
                    formControlName="password2"
                  />
                  <mat-error
                    *ngIf="
                      form.controls.password2.touched &&
                      form.controls.password2.invalid
                    "
                    class="validation"
                  >
                    <span *ngIf="form.controls.password2.errors?.required">
                      Введите пароль
                    </span>
                    <span *ngIf="form.controls.password2.errors?.pattern">
                      Введите только латинские буквы и цифры
                    </span>
                    <span *ngIf="form.controls.password2.errors?.minlength">
                      Пароль должен быть не менее
                      {{
                        form.controls.password2.errors?.minlength.requiredLength
                      }}
                      символов. Сейчас он
                      {{
                        form.controls.password2.errors?.minlength.actualLength
                      }}.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>

          <div class="row" *ngIf="mode === 1">
            <div class="col-6">
              <button
                class="btn btn-outline-primary example-full-width"
                type="submit"
                color="primary"
                (click)="openPasswordDialog(password)"
              >
                Изменить пароль
              </button>
            </div>
            <div class="col-6">
              <button
                *ngIf="userForm.isEnabled === 'true'"
                class="btn btn-outline-danger example-full-width"
                type="submit"
                color="primary"
                (click)="handleDisabledUser(userForm.id)"
              >
                Заблокировать пользователя
              </button>
              <button
                *ngIf="userForm.isEnabled === 'false'"
                class="btn btn-outline-success example-full-width"
                type="submit"
                color="primary"
                (click)="handleEnabledUser(userForm.id)"
              >
                Разблокировать пользователя
              </button>
            </div>
          </div>
          <div class="row m-t-10">
            <div class="col-6">
              <button
                class="example-full-width"
                type="submit"
                mat-stroked-button
                (click)="form.reset()"
              >
                Очистить
              </button>
            </div>
            <div class="col-6">
              <button
                class="example-full-width"
                type="submit"
                mat-flat-button
                color="primary"
                (click)="saveUser()"
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div *ngIf="isDetialFuncRoleDisplay" class="card">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-description>
                <span class="col-orange"> Функциональные роли </span>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <ul>
                <li *ngFor="let item of funcRoleDetails">
                  <div class="row">
                    <div class="col-10">
                      <span>
                        {{ item.name }}
                      </span>
                    </div>
                    <div class="col-2">
                      <span>
                        <mat-checkbox
                          class="example-margin"
                          [disabled]="true"
                          [checked]="true"
                        ></mat-checkbox>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div *ngIf="isDetialLayerRoleDisplay" class="card">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-description>
                <span class="col-orange"> Роли по доступу к слоям </span>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <ul>
                <li>
                  <div class="row">
                    <div class="col-8"></div>
                    <div class="col-2">READ</div>
                    <div class="col-2">WRITE</div>
                  </div>
                </li>
                <li *ngFor="let item of layerRoleDetails">
                  <div class="row">
                    <div class="col-8">
                      <span>
                        {{ item.nameRu ?? item.layerId }}
                      </span>
                    </div>
                    <div class="col-2">
                      <span>
                        <mat-checkbox
                          class="example-margin"
                          [disabled]="true"
                          [checked]="
                            item.permission === 'READ' ||
                            item.permission === 'WRITE'
                          "
                        ></mat-checkbox>
                      </span>
                    </div>
                    <div class="col-2">
                      <span>
                        <mat-checkbox
                          class="example-margin"
                          [disabled]="true"
                          [checked]="item.permission === 'WRITE'"
                        ></mat-checkbox>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>

<ng-template #addDic>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Добавление функицональных ролей
    </h4>
    <span aria-hidden="true" [mat-dialog-close]
      ><i class="material-icons">close</i></span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label> Введите название </mat-label>
          <input
            matInput
            autocomplete="off"
            [(ngModel)]="nameRole"
            appearance="outline"
          />
        </mat-form-field>
      </div>
    </div>
    <ul>
      <li *ngFor="let item of functionLists">
        <div class="row">
          <div class="col-10">
            <p>
              {{ item.name }}
            </p>
          </div>
          <div class="col-2">
            <p>
              <mat-checkbox
                class="example-margin"
                (change)="handleCheckBoxFuncRole($event, item)"
              ></mat-checkbox>
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button
      mat-raised-button
      type="button"
      color="primary"
      (click)="saveFuncRole()"
    >
      Сохранить
    </button>
    <button mat-button type="button" [mat-dialog-close]>Отмена</button>
  </div>
</ng-template>

<ng-template #addLayer>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Добавление ролей слоев</h4>
    <span aria-hidden="true" [mat-dialog-close]
      ><i class="material-icons">close</i></span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label> Введите название </mat-label>
          <input
            matInput
            autocomplete="off"
            [(ngModel)]="nameRole"
            appearance="outline"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          matSort
          style="width: 100%"
        >
          <ng-container matColumnDef="layerId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element">
              {{ element.nameRu }}
            </td>
          </ng-container>

          <ng-container matColumnDef="permissionRead">
            <th mat-header-cell *matHeaderCellDef>READ</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                class="example-margin"
                #read
                [(ngModel)]="element.permissionRead"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="permissionWrite">
            <th mat-header-cell *matHeaderCellDef>WRITE</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                class="example-margin"
                #write
                [(ngModel)]="element.permissionWrite"
                (change)="eventCheckWrite($event, element)"
              ></mat-checkbox>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mat-raised-button
      type="button"
      color="primary"
      (click)="saveLayerRole()"
    >
      Сохранить
    </button>
    <button mat-button type="button" [mat-dialog-close]>Отмена</button>
  </div>
</ng-template>

<ng-template #password>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Изменить пароль</h4>
    <span aria-hidden="true" [mat-dialog-close]
      ><i class="material-icons">close</i></span
    >
  </div>
  <div class="modal-body">
    <form class="dialog_form" [formGroup]="formPassword">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label> Новый пароль </mat-label>
            <input
              matInput
              type="password"
              autocomplete="off"
              required
              formControlName="newPassword"
            />
            <mat-error
              *ngIf="
                formPassword.controls.newPassword.touched &&
                formPassword.controls.newPassword.invalid
              "
              class="validation"
            >
              <span *ngIf="formPassword.controls.newPassword.errors?.required">
                Введите пароль
              </span>
              <span *ngIf="formPassword.controls.newPassword.errors?.pattern">
                Введите только латинские буквы и цифры
              </span>
              <span *ngIf="formPassword.controls.newPassword.errors?.minlength">
                Пароль должен быть не менее
                {{
                  formPassword.controls.newPassword.errors?.minlength
                    .requiredLength
                }}
                символов. Сейчас он
                {{
                  formPassword.controls.newPassword.errors?.minlength
                    .actualLength
                }}.
              </span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row m-t-10">
        <div class="col-12">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label> Подтверждение </mat-label>
            <input
              matInput
              type="password"
              autocomplete="off"
              required
              formControlName="newPassword2"
            />
            <mat-error
              *ngIf="
                formPassword.controls.newPassword2.touched &&
                formPassword.controls.newPassword2.invalid
              "
              class="validation"
            >
              <span *ngIf="formPassword.controls.newPassword2.errors?.required">
                Введите пароль
              </span>
              <span *ngIf="formPassword.controls.newPassword2.errors?.pattern">
                Введите только латинские буквы и цифры
              </span>
              <span
                *ngIf="formPassword.controls.newPassword2.errors?.minlength"
              >
                Пароль должен быть не менее
                {{
                  formPassword.controls.newPassword2.errors?.minlength
                    .requiredLength
                }}
                символов. Сейчас он
                {{
                  formPassword.controls.newPassword2.errors?.minlength
                    .actualLength
                }}.
              </span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="col-12"></div>
  </div>
  <div class="modal-footer">
    <button
      mat-raised-button
      type="button"
      color="primary"
      (click)="changePassword()"
    >
      Сохранить
    </button>
    <button mat-button type="button" [mat-dialog-close]>Отмена</button>
  </div>
</ng-template>
