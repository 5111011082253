export interface CloseRoadEventsResponseType {
    totalPages: number;
    totalElements: number;
    size: number;
    content: CloseRoadEventsType[];
    number: number;
    sort: {
        empty: number,
        sorted: number;
        unsorted: number;
    };
    pageable: {
        offset: number;
        sort: {
            empty: number;
            sorted: number;
            unsorted: number;
        },
        pageNumber: number;
        pageSize: number;
        paged: number;
        unpaged: number;
    };
    numberOfElements: number;
    empty: number;
}

export interface CloseRoadEventsType {
    gid: number,
    user_name: string,
    name_kz: string,
    name_ru: string,
    name_en: string,
    start_date: string,
    end_date: string,
    closed: number
}

export interface CloseRoadEventsChartDataType {
    name_ru: string;
    opened: number;
    closed: number;
}

export const CloseRoadChartElementsPerGage = 4;
