import {RouteInfo} from './sidebar.metadata';
import {RoleTypes} from '../../model/constants';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Меню',
    moduleName: '',
    icon: '',
    class: 'header',
    groupTitle: true,
    submenu: [],
    access: [
      RoleTypes.ADMIN,
      RoleTypes.SUPERADMIN
    ],
  },
  {
    path: '',
    title: 'Дерева слоев',
    moduleName: 'tree-layer',
    icon: 'map',
    class: 'menu-toggle',
    groupTitle: false,
    access: [
      RoleTypes.ADMIN,
      RoleTypes.SUPERADMIN
    ],
    submenu: [
      {
        path: '/tree-layer/tree-layer-folders',
        title: 'Группа слоев',
        moduleName: 'tree-layer',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
    access: [
      RoleTypes.ADMIN,
      RoleTypes.SUPERADMIN
    ],
      },
      {
        path: '/tree-layer/tree-layer-list',
        title: 'Список слоев',
        moduleName: 'tree-layer',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
    access: [
      RoleTypes.ADMIN,
      RoleTypes.SUPERADMIN
    ],
      },
      {
        path: '/tree-layer/tree-layer-attributes',
        title: 'Атрибуты слоев',
        moduleName: 'tree-layer',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
    access: [
      RoleTypes.ADMIN,
      RoleTypes.SUPERADMIN
    ],
      },
      // {
      //   path: '/tree-layer/tree-layer-create',
      //   title: 'Создание слоев',
      //   moduleName: 'tree-layer',
      //   icon: '',
      //   class: '',
      //   groupTitle: false,
      //   submenu: [],
      // },
    ],
  },
  {
    path: '/identify',
    title: 'Идентификация',
    moduleName: 'identify',
    icon: 'info',
    class: '',
    groupTitle: false,
    submenu: [],
    access: [
      RoleTypes.ADMIN,
      RoleTypes.SUPERADMIN
    ],
  },
  {
    path: '/filter',
    title: 'Поиск',
    moduleName: 'filter',
    icon: 'search',
    class: '',
    groupTitle: false,
    submenu: [],
    access: [
      RoleTypes.ADMIN,
      RoleTypes.SUPERADMIN
    ],
  },
  {
    path: '/logging',
    title: 'Журнал логирования',
    moduleName: 'filter',
    icon: 'git-pull-request',
    class: '',
    groupTitle: false,
    submenu: [],
    access: [
      RoleTypes.ADMIN,
      RoleTypes.SUPERADMIN
    ],
  },
  // {
  //   path: '/tariff',
  //   title: 'Управление тарифами',
  //   moduleName: 'filter',
  //   icon: 'book-open',
  //   class: '',
  //   groupTitle: false,
  //   submenu: [],
  // },
  {
    path: '/users',
    title: 'Пользователи',
    moduleName: 'users',
    icon: 'user-check',
    class: '',
    groupTitle: false,
    submenu: [],
    access: [
      RoleTypes.SUPERADMIN,
      RoleTypes.ADMIN
    ],
  },
  {
    path: '/comments',
    title: 'Комментарии к ОПС',
    moduleName: 'rso-comments',
    icon: 'message-square',
    class: '',
    groupTitle: false,
    submenu: [],
    access: [
      RoleTypes.SUPERADMIN,
      RoleTypes.RSO_SPECIALIST
    ],
  },
  {
    path: '/events',
    title: 'События',
    moduleName: 'events',
    icon: 'image',
    class: '',
    groupTitle: false,
    submenu: [],
    access: [
      RoleTypes.ROD_OPERATOR,
      RoleTypes.ROD_SUPERVISOR,
      RoleTypes.ROD_SPECIALIST,
      RoleTypes.SUPERADMIN
    ],
  },
  {
    path: '/events-report',
    title: 'Отчетная форма "События"',
    moduleName: 'events-report',
    icon: 'file-text',
    class: '',
    groupTitle: false,
    submenu: [],
    access: [
      RoleTypes.ROLE_SUPERVISOR,
      RoleTypes.SUPERADMIN
    ],
  },
  {
    path: '/close-road-report',
    title: 'Отчетная форма по закрытию дорог',
    moduleName: 'close-road-report',
    icon: 'lock',
    class: '',
    groupTitle: false,
    submenu: [],
    access: [
      RoleTypes.ROLE_SUPERVISOR,
      RoleTypes.SUPERADMIN
    ],
  },
  {
    path: '/notifications',
    title: 'Push-уведомления',
    moduleName: 'notifications',
    icon: 'mail',
    class: '',
    groupTitle: false,
    submenu: [],
    access: [
      RoleTypes.SPECIALIST,
      RoleTypes.SUPERADMIN
    ],
  },
];
