import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {RoleNames, RoleTypes} from 'src/app/model/constants';
import {Role, User} from 'src/app/model/user';
import {AdminService} from 'src/app/services/admin.service';
import {NotificationService} from 'src/app/shared/services/notification.service';
import {AuthService} from '../../authentication/service/auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = [
    'lastName',
    'firstName',
    'email',
    'isEnabled',
    'role',
    'functionRole',
    'layerRole',
    'delete',
  ];

  dataSource = new MatTableDataSource();

  roles: Role[] = [];

  isEditAccess: boolean = false;

  isEnabled = [
    { nameRu: 'Активный', id: 1 },
    { nameRu: 'Заблокирован', id: 2 },
  ];

  constructor(
    private matDialog: MatDialog,
    private ntf: NotificationService,
    private adminService: AdminService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    const roles = this.authService.getUser().authorities;
    if (roles.includes(RoleTypes.RSO_SPECIALIST)) {
      this.router.navigate(['/comments']);
    } else if (
        roles.includes(RoleTypes.ROD_SUPERVISOR) ||
        roles.includes(RoleTypes.ROD_SPECIALIST) ||
        roles.includes(RoleTypes.ROD_OPERATOR)
    ) {
      this.router.navigate(['/events']);
    } else if (
        roles.includes(RoleTypes.ROLE_SUPERVISOR)
    ) {
      this.router.navigate(['/events-report']);
    } else if (
        roles.includes(RoleTypes.SPECIALIST)
    ) {
      this.router.navigate(['/notifications']);
    } else {
      this.getAllUsers();
      this.getAllLayersTree();
    }
  }

  toUserEditForm(element: any) {
    if (!element) {
      return;
    }
    this.router.navigate(['/user', element.id], {
      queryParams: {
        ...element,
        functionRole: JSON.stringify(element.functionRole),
        layerRole: JSON.stringify(element.layerRole),
      },
      skipLocationChange: true
    });
  }

  removeUser(user: User) {
    if (!user.id) {
      return;
    }
    this.ntf
      .confirmSwal('Удаление', 'Удалить безвозвратно?', 'question')
      .then((result: any) => {
        if (result.isConfirmed) {
          this.ntf.showLoader();
          this.adminService.removeUser(user.id).subscribe({
            next: (data) => {
              this.ntf.hideLoader();
              this.getAllUsers();
              this.ntf.isConfirmed('Удаление', 'Успешно удален', 'success');
            },
            error: (e) => {
              this.ntf.hideLoader();
            },
          });
        }
      });
  }

  refresh() {
    this.dataSource = new MatTableDataSource([]);
    this.ntf.showLoader();
    try {
      setTimeout(() => {
        this.getAllUsers();
      }, 400)
    } catch (error) {
      console.error(error);
      this.ntf.hideLoader();
    }
  }

  getAllUsers() {
    this.ntf.showLoader();
    this.adminService.getAllUsers().subscribe({
      next: (users) => {
        this.ntf.hideLoader();
        this.dataSource = new MatTableDataSource(users);
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  getRoleName(code: string): string {
    return RoleNames[code];
  }

  getAllLayersTree() {
    this.adminService.getAllLayersTree().subscribe({
      next: (lists) => {
        this.adminService.admLayersList = lists;
      },
      error: (e) => console.error(e),
    });
  }
}
