import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {NotificationService} from '../../shared/services/notification.service';
import {AdminService} from '../../services/admin.service';
import {
  EventCategoryName,
  EventStatus,
  EventStatusDetails,
  EventStatusForFilter,
  EventType,
  GetEventsResponse,
} from './models/events.model';
import {AuthService} from "../../authentication/service/auth.service";
import {gisitsApi, RoleTypes} from "../../model/constants";
import {KatoService} from "../../shared/services/kato.service";
import {MatDialog} from "@angular/material/dialog";
import {EventChangeStatusComponent} from "./event-change-status/event-change-status.component";
import {User} from "../../model/user";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.sass']
})
export class EventsComponent implements OnInit {
  displayedColumns: string[] = [
    'gid',
    'category',
    'userInfo',
    'kato',
    'descriptionRu',
    'sendPoint',
    'employee',
    'status',
  ];

  isOperator = false;
  isSuperAdmin = false;
  isRegionOperator = false;
  notSelectedText = 'Не выбрано';
  eventStatus = EventStatus;
  statusList = EventStatusForFilter;
  eventStatusDetails = EventStatusDetails;
  eventCategoryName = EventCategoryName;
  dataSource = new MatTableDataSource<EventType>();
  filterByKato = this.notSelectedText;
  filterByStatus = this.notSelectedText;
  searchValue = null;
  pageSize = 10;
  pageIndex = 0;
  totalElements = null;
  pageEvent: PageEvent;
  emplEmail = '';
  user: User;

  constructor(
      private ntf: NotificationService,
      private adminService: AdminService,
      private authService: AuthService,
      public kato: KatoService,
      public dialog: MatDialog,
  ) {
  }

  @ViewChild('paginator') paginator: MatPaginator;
  @Output() page: EventEmitter<PageEvent>;

  ngOnInit() {
    const user = this.authService.getUser();
    this.user = user;
    const newDisplayedColumns = [
      'gid',
      'category',
      'userInfo',
      'descriptionRu',
      'sendPoint',
      'employee',
      'status',
    ];
    if (user.authorities.includes(RoleTypes.ROD_OPERATOR)) {
      this.isOperator = true;
      if (user.kato) {
        this.isRegionOperator = true;
        this.filterByKato = user.kato;
        this.displayedColumns = newDisplayedColumns;
      }
    } else if (user.authorities.includes(RoleTypes.SUPERADMIN)) {
      this.isOperator = true;
      this.isSuperAdmin = true;
    } else {
      this.emplEmail = user.email;
      this.statusList = EventStatusForFilter.filter(item => item.status !== EventStatus.DRAFT);
      this.displayedColumns = newDisplayedColumns;
    }
    this.getEvents();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalElements = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getEvents();
  }

  openChangeEventStatusModal(event: EventType) {
    if (
        (this.isOperator && this.isRegionOperator && event.status === EventStatus.DRAFT) ||
        (this.emplEmail && event.status === EventStatus.IN_PROGRESS) ||
        (this.isSuperAdmin && event.status === EventStatus.DRAFT)
    ) {
      let dialogRef = this.dialog.open(EventChangeStatusComponent, {
        data: {
          event,
          userRole: this.user.authorities,
          userKato: event.kato,
          status: event.status,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const timer = setTimeout(() => {
            this.getEvents();
            return () => clearTimeout(timer);
          }, 1000);
        }
      });
    }
  }

  applyFilter() {
    this.pageIndex = 0;
    this.ntf.showLoader();
    this.getEvents();
  }

  onSelectKato(kato: string) {
    this.pageIndex = 0;
    this.filterByKato = kato;
    this.getEvents();
  }

  onSelectStatus() {
    this.pageIndex = 0;
    this.getEvents();
  }

  removeSearchValue() {
    this.pageIndex = 0;
    this.searchValue = null;
    this.getEvents();
  }

  removeFilterValues() {
    this.pageIndex = 0;
    if (this.isOperator && !this.isRegionOperator) {
      this.filterByKato = this.notSelectedText;
    }
    this.filterByStatus = this.notSelectedText;
    this.getEvents();
  }

  getEvents() {
    this.ntf.showLoader();
    const status = this.filterByStatus !== this.notSelectedText ? this.filterByStatus : '';
    const kato = this.filterByKato !== this.notSelectedText ? this.filterByKato : '';
    const emplEmail = this.user.authorities.includes(RoleTypes.ROD_SPECIALIST) ? this.emplEmail : '';
    this.adminService.getEvents(this.pageIndex, this.pageSize, status, kato, this.searchValue, emplEmail)
        .subscribe({
          next: (data: GetEventsResponse) => {
            const {content, totalElements} = data;
            this.totalElements = totalElements;
            this.dataSource = new MatTableDataSource(content);
            this.ntf.hideLoader();
          },
          error: (e) => {
            this.ntf.hideLoader();
            console.error(e);
          },
        });
  }

  getSendPoints(eventSendPoint: string) {
    const sendPoint = eventSendPoint.split(' '); // example ['POINT', '(7953330.111719295', '6653213.370593391)']
    const sendPoint1 = sendPoint[1].slice(1, sendPoint[1].length); // 7953330.111719295
    const sendPoint2 = sendPoint[2].slice(0, sendPoint[2].length - 1); // 6653213.370593391
    return {
      sendPoint1,
      sendPoint2,
    }
  }

  openMapHandler(event: EventType) {
    const { sendPoint1, sendPoint2 } = this.getSendPoints(event.sendPoint);
    const link = `${gisitsApi}/events-redirect?gid=${event.gid}&sendPoint1=${sendPoint1}&sendPoint2=${sendPoint2}`;
    this.openLink(link);
  }

  openLink(link: string) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  async downloadFileBitrix(base64Data: string) {
    console.log(base64Data)
    const base64Response = await fetch(base64Data);
    const blob = await base64Response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', blobUrl);
    link.setAttribute('download','file');
    link.click();
  }

  downloadFile(uid: string) {
    this.ntf.showLoader();
    console.log(uid)
    this.adminService.filesDownload(uid).subscribe({
      next: ({ body, headers }: any) => {
        const cd = headers.get("content-disposition");
        const filename = cd && cd.substring(cd.indexOf("=") + 1, cd.length);
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(body);
        downloadLink.download = filename || "file";
        downloadLink.click();
        this.ntf.hideLoader();
      },
      error: (e) => {
          this.ntf.hideLoader();
          console.error(e);
      }
    });
  }
}
