<section class="content">
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="header">
            <h2><strong>Настройка идентификации</strong></h2>
          </div>
          <div class="body table-responsive">
            <mat-paginator
              [pageSizeOptions]="[20, 50, 100]"
              aria-label="Select page of users"
            ></mat-paginator>
            <table mat-table [dataSource]="dataSource" class="w-100" matSort>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let element; let idx = index">
                  {{ element.id }}
                </td>
              </ng-container>

              <ng-container matColumnDef="nameRu">
                <th mat-header-cell *matHeaderCellDef>Наименование</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.nameRu }}
                </td>
              </ng-container>

              <ng-container matColumnDef="nameKz">
                <th mat-header-cell *matHeaderCellDef>Наименование (каз)</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.nameKz }}
                </td>
              </ng-container>

              <ng-container matColumnDef="layerName">
                <th mat-header-cell *matHeaderCellDef>Наименование слоя</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.layerName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="identify">
                <th mat-header-cell *matHeaderCellDef>Идентифицировать</th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox
                    #myCheckbox
                    [checked]="element.identify"
                    class="example-margin"
                    (click)="identify(element, !element.identify)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
