import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { INTERCEPTOR } from '../../model/constants';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private router: Router,
    private ntf: NotificationService
  ) { }

  addAuthHeader(request: any) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      },
    });
  }

  setTokens(token: any): any {
    localStorage.setItem('access_token', token.access_token);
    localStorage.setItem('refresh_token', token.refresh_token);
    return token;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('/api/authenticate')) {
      return next.handle(request);
    }

    request = this.addAuthHeader(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('username');
          this.router.navigate(['/signin'], {
            queryParams: {
              from: INTERCEPTOR,
              status: '401',
            },
          });
          return throwError(error);
        } else if (error.status === 500) {
          this.ntf.showNotification(
            'snackbar-danger',
            'Ошибка на стороне сервера обратитесь в тех.поддержку'
          );
        } else if (error.status === 400) {
          if (error.error) {

            if (error.error.code == 1) {
              this.ntf.showNotification('snackbar-danger', 'Пользователь не найден!');
              return;
            } else if (error.error.code == 10) {
              this.ntf.showNotification('snackbar-danger', 'Пользователь не авторизован!');
              return;
            } else if (error.error.code == 13) {
              this.ntf.showNotification('snackbar-danger', 'Переданные данные не правильны!');
              return;
            } else if (error.error.code == 16) {
              this.ntf.showNotification('snackbar-danger', 'Пользователь не активирован!');
            } else if (error.error.code == 101) {
              this.ntf.showNotification('snackbar-danger', 'Нарушено ограничение уникальности электронной почты');
              return;
            } else if (error.error.code == 102) {
              this.ntf.showNotification('snackbar-danger', 'Нарушено ограничение уникального кода');
              return;
            } else if (error.error.code == 103) {
              this.ntf.showNotification('snackbar-danger', 'Невозможно переназначить связанных пользователей на другую роль перед ее удалением');
              return;
            } else {
              this.ntf.showNotification('snackbar-danger', 'Не правильный запрос');
            }

          } else if (!error.error) {
            this.ntf.showNotification('snackbar-danger', 'Не обработанная ошибка');
            return;
          } else {
            this.ntf.showNotification('snackbar-danger', 'Ошибка');
          }
        } else {
          this.ntf.showNotification('snackbar-danger', 'Ошибка на стороне сервера');
          return;
        }
        return throwError(error);
      })
    );
  }
}


// TODO: 2 variant
// import { Injectable } from '@angular/core';
// import {
//   HttpRequest,
//   HttpHandler,
//   HttpEvent,
//   HttpInterceptor
// } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable()
// export class RefreshTokenInterceptor implements HttpInterceptor {

//   constructor() { }

//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     return next.handle(this.addAuthToken(request));
//   }

//   addAuthToken(request: HttpRequest<any>) {
//     const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhcm1hbkBtYWlsLnJ1IiwiYXV0aCI6IlJPTEVfRU1QTE9ZRUUsUk9MRV9BRE1JTiIsImV4cCI6MTY2Nzk3NDM1MX0.-_1QWDiPwfIVcTaeO1ce846kxjEZRR9F-jSb89cDbirV3pPdwH9m4d4e-5l-ChVs5hS-LGt4_qZ40nbs4eJQbw'

//     return request.clone({
//       setHeaders: {
//         Authorization: `Bearer ${token}`
//       }
//     })
//   }
// }