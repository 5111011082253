import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  FormMode, RoleNames,
  RoleTypes,
} from 'src/app/model/constants';
import { User } from 'src/app/model/user';
import { AdminService } from 'src/app/services/admin.service';
import { FRoleDetail, FunctionList, FunctionRole, LayerRole, LRoleDetail } from 'src/app/shared/interfaces';
import { NotificationService } from 'src/app/shared/services/notification.service';
import {KatoService} from "../../../../shared/services/kato.service";

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  displayedColumns: string[] = [
    'layerId',
    'permissionRead',
    'permissionWrite',
  ];
  @Input() userForm: User;
  mode: FormMode;
  form: FormGroup;
  formPassword: FormGroup;
  roles: [] = [];
  layerRoles: LayerRole[] = [];
  functionRoles: FunctionRole[] = [];
  functionLists: FunctionList[] = []
  isEnabled = [
    { nameRu: 'Активный', id: 1 },
    { nameRu: 'Заблокирован', id: 2 },
  ];

  funcRoleDetails: FRoleDetail[] = [];
  layerRoleDetails: LRoleDetail[] = [];

  isDetialFuncRoleDisplay: boolean = false;
  isDetialLayerRoleDisplay: boolean = false;

  nameRole: string = '';
  funcRoleList: FRoleDetail[] = [];
  selectLayersListRead: any = [];
  selectLayersListWrite: any = [];

  dataSource: any = new MatTableDataSource();

  @ViewChild('mySelect1') mySelect1;
  @ViewChild('mySelect2') mySelect2;

  constructor(
    private adminService: AdminService,
    private matDialog: MatDialog,
    private ntf: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    public kato: KatoService,
  ) {
    this.mode = FormMode.View;
  }

  ngOnInit(): void {
    this.getAllLayersTree();
    if (!this.isEmpty(this.userForm)) {
      this.mode = FormMode.Edit;
      let userInfo: any = {
        ...this.userForm,
        functionRole: JSON.parse(this.userForm.functionRole.toString()),
        layerRole: JSON.parse(this.userForm.layerRole.toString()),
      }
      this.form = this.initForm(userInfo);
      this.displaySelectRoles();
      this.setDisableForControls(this.form.controls, 'password');
      this.setDisableForControls(this.form.controls, 'password2');
    } else {
      this.mode = FormMode.New;
      this.form = this.initForm();
    }
    this.getAllRoles();
    this.getAllLayerRoles();
    this.getAllFunctionRoles();
    this.kato.getKatoList();
  }

  initForm(user?: User) {
    if (user == null) {
      return new FormGroup({
        lastName: new FormControl(''),
        firstName: new FormControl(''),
        kato: new FormControl(''),
        authorities: new FormControl(''),
        layerRole: new FormControl(''),
        functionRole: new FormControl(''),
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[A-Za-z0-9]+$/),
          Validators.minLength(6),
        ]),
        password2: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[A-Za-z0-9]+$/),
          Validators.minLength(6),
        ]),
      });
    } else {
      return (this.form = new FormGroup({
        id: new FormControl(+user.id),
        lastName: new FormControl(user.lastName),
        firstName: new FormControl(user.firstName),
        kato: new FormControl(user.kato),
        authorities: new FormControl(user.authorities[0]),
        layerRole: new FormControl(user.layerRole),
        functionRole: new FormControl(user.functionRole),
        email: new FormControl(user.email, [
          Validators.required,
          Validators.email,
        ]),
        password: new FormControl(user.password, [
          Validators.required,
          Validators.pattern(/^[A-Za-z0-9]+$/),
          Validators.minLength(6),
        ]),
        password2: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[A-Za-z0-9]+$/),
          Validators.minLength(6),
        ]),
      }));
    }
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  toDetailFuncRole(event: any) {
    if (event && event.value && event.value.functions) {
      this.isDetialFuncRoleDisplay = true;
      // this.isDetialLayerRoleDisplay = false;
      this.funcRoleDetails = event?.value?.functions;
    } else {
      this.isDetialFuncRoleDisplay = false;
    }
  }

  toDetailLayerRole(event: any) {
    if (event && event.value && event.value.permissions) {
      this.isDetialLayerRoleDisplay = true;
      // this.isDetialFuncRoleDisplay = false;
      this.layerRoleDetails = this.getPermissionsName(this.adminService.admLayersList, event?.value?.permissions);
    } else {
      this.isDetialLayerRoleDisplay = false;
    }
  }

  displaySelectRoles() {
    if (this.mode === FormMode.Edit) {
      const f = this.form.value;
      if (f.functionRole) {
        this.isDetialFuncRoleDisplay = true;
        this.funcRoleDetails = f.functionRole?.functions;
      }
      if (f.layerRole) {
        this.isDetialLayerRoleDisplay = true;
        this.layerRoleDetails = this.getPermissionsName(this.adminService.admLayersList, f.layerRole?.permissions);
      }
    }
  }

  eventCheckWrite(event: { checked: boolean }, el: any) {
    this.adminService.admLayersList.forEach((item: any) => {
      if (item.id === el.id) {
        item.permissionRead = event.checked ? true : false;
      }
    });
  }

  getPermissionsName(lists: any, permissions: LRoleDetail[]) {
    if (!lists.length) return permissions;

    let result = [];

    lists.forEach(el => {
      permissions.forEach(item => {
        if (el.id === item.layerId) {
          result.push({
            layerId: item.layerId,
            permission: item.permission,
            nameRu: el.nameRu,
            layerName: el.layerName
          })
        }
      })
    })

    return result
  }

  handleCheckBoxFuncRole(event: any, item: FRoleDetail) {
    if (event.checked && item) {
      this.funcRoleList.push(item)
    } else {
      this.funcRoleList = this.funcRoleList.filter(el => el.id !== item.id)
    }
  }

  handleCheckBoxLayerRoleWrite(event: any, item: any, status: string) {
    if (!item) return;

    if (event.checked) {
      if (!this.selectLayersListWrite.some(el => el.id === item.id)) {
        let obj = {
          permission: status,
          layerId: item.id
        }
        this.selectLayersListWrite.push(obj)
      }
    } else {
      this.selectLayersListWrite = this.selectLayersListWrite.filter(el => el.layerId !== item.id);
    }
  }

  handleCheckBoxLayerRoleRead(event: any, item: any, status: string) {
    if (!item) return;

    if (event.checked) {
      if (!this.selectLayersListRead.some(el => el.id === item.id)) {
        let obj = {
          permission: status,
          layerId: item.id
        }
        this.selectLayersListRead.push(obj)
      }
    } else {
      this.selectLayersListRead = this.selectLayersListRead.filter(el => el.layerId !== item.id);
    }
  }

  handleDisabledUser(id: number) {
    if (!id) return;
    this.ntf
      .confirmSwal('', 'Заблокировать пользователя?', 'question')
      .then((result: any) => {
        if (result.isConfirmed) {
          this.ntf.showLoader();
          this.adminService.disableUser(id).subscribe({
            next: () => {
              this.ntf.hideLoader();
              this.ntf.showNotification('snackbar-success', 'Пользователь заблокирован');
              this.router.navigate(['/users'])
            },
            error: (err) => {
              console.error(err);
              this.ntf.hideLoader();
            }
          })
        }
      })
  }

  handleEnabledUser(id: number) {
    if (!id) return;
    this.ntf
      .confirmSwal('', 'Разблокировать пользователя?', 'question')
      .then((result: any) => {
        if (result.isConfirmed) {
          this.ntf.showLoader();
          this.adminService.enableUser(id).subscribe({
            next: () => {
              this.ntf.hideLoader();
              this.ntf.showNotification('snackbar-success', 'Пользователь разблокирован');
              this.router.navigate(['/users'])
            },
            error: (err) => {
              console.error(err);
              this.ntf.hideLoader();
            }
          })
        }
      })
  }

  openDialogAddFuncRole(modal: any) {
    this.getListFunctions();
    this.mySelect1.close();
    this.matDialog.open(modal, {
      maxHeight: '70vh',
      minHeight: '55vh',
      minWidth: '55vw',
      maxWidth: '75vw',
      disableClose: true,
    }).beforeClosed().subscribe({
      next: () => {
        this.nameRole = '';
        this.funcRoleList = []
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  openDialogAddLayerRole(modal: any) {
    this.mySelect2.close();
    this.nameRole = '';
    this.selectLayersListWrite = [];
    this.selectLayersListRead = [];
    this.matDialog.open(modal, {
      maxHeight: '70vh',
      minHeight: '55vh',
      minWidth: '55vw',
      maxWidth: '75vw',
      disableClose: true,
    }).afterOpened()
      .subscribe({
        next: () => {
          this.dataSource = new MatTableDataSource(this.adminService.admLayersList);
        },
        error: (error) => {
          console.error(error);
        }
      })
  }

  openPasswordDialog(modal: any) {
    this.formPassword = this.formBuilder.group({
      newPassword: [
        '',
        [Validators.required,
        Validators.pattern(/^[A-Za-z0-9]+$/),
        Validators.minLength(6)],
      ],
      newPassword2: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[A-Za-z0-9]+$/),
          Validators.minLength(6),
        ],
      ],
    });
    this.matDialog.open(modal, {
      maxHeight: '70vh',
      minHeight: '35vh',
      minWidth: '35vw',
      maxWidth: '75vw',
      disableClose: true,
    })
  }

  saveFuncRole() {
    if (!this.nameRole.trim()) {
      this.ntf.showNotification('snackbar-info', 'Введите название');
      return
    }
    if (!this.funcRoleList.length) {
      this.ntf.showNotification('snackbar-info', 'Выберите хотя бы один функционал');
      return
    }
    let body = { code: this.nameRole.trim(), functions: this.funcRoleList, isUserSpecific: false };
    this.adminService.saveFunctionRole(body).subscribe({
      next: (res) => {
        this.ntf.showNotification('snackbar-success', 'Успешно сохранен')
        this.getAllFunctionRoles();
        this.matDialog.closeAll();
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  saveLayerRole() {
    if (!this.nameRole.trim()) {
      this.ntf.showNotification('snackbar-info', 'Введите название');
      return
    }

    const v = this.dataSource._data._value;

    let permissions = v.map(el => {
      if (el.permissionWrite || el.permissionRead) {
        return { layerId: el.id, permission: el.permissionWrite ? 'WRITE' : 'READ' };
      }
    }).filter(el => el !== undefined)

    let body = {
      code: this.nameRole,
      isUserSpecific: false,
      permissions: permissions
    }

    this.adminService.saveLayerRole(body).subscribe({
      next: (res) => {
        this.ntf.showNotification('snackbar-success', 'Успешно сохранен')
        this.getAllLayerRoles();
        this.matDialog.closeAll();
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  deleteFuncRole(fRole: FunctionRole) {
    if (!fRole) return;
    this.ntf
      .confirmSwal('Удаление', 'Удалить безвозвратно?', 'question')
      .then((result: any) => {
        if (result.isConfirmed) {
          this.ntf.showLoader();
          this.adminService.deleteFunctionRole(fRole.id).subscribe({
            next: (data) => {
              this.ntf.hideLoader();
              this.getAllFunctionRoles();
              this.ntf.isConfirmed('Удаление', 'Успешно удален', 'success');
              this.isDetialFuncRoleDisplay = false;
            },
            error: (e) => {
              this.ntf.hideLoader();
            },
          });
        }
      });
  }

  deleteLayerRole(lRole: LayerRole) {
    if (!lRole) return;
    this.ntf
      .confirmSwal('Удаление', 'Удалить безвозвратно?', 'question')
      .then((result: any) => {
        if (result.isConfirmed) {
          this.ntf.showLoader();
          this.adminService.deleteLayerRole(lRole.id).subscribe({
            next: (data) => {
              this.ntf.hideLoader();
              this.getAllLayerRoles();
              this.ntf.isConfirmed('Удаление', 'Успешно удален', 'success');
              this.isDetialLayerRoleDisplay = false;
            },
            error: (e) => {
              this.ntf.hideLoader();
            },
          });
        }
      });
  }

  setDisableForControls(formName: any, contolName: string) {
    formName[contolName].disable();
  }

  setEnableForControls(formName: any, contolName: string) {
    formName[contolName].enable();
  }

  saveUser() {
    if (this.form.invalid) {
      this.ntf.showNotification('snackbar-info', 'Заполните все поля')
      return
    }
    const f = this.form.value;
    if (this.mode === FormMode.New && (f.password !== f.password2)) {
      this.ntf.showNotification('snackbar-info', 'Пароли не совпадают')
      return
    }
    let body = { ...f, authorities: f.authorities }
    if (!Array.isArray(f.authorities)) {
      body = { ...f, authorities: [f.authorities] }
    }
    if (this.mode === FormMode.New) {
      this.ntf
        .confirmSwal('Сохранение', 'Сохранить пользователя?', 'question')
        .then((result) => {
          if (result.isConfirmed) {
            this.ntf.showLoader();
            this.adminService.saveUser(body).subscribe({
              next: (res) => {
                this.ntf.hideLoader();
                this.ntf.showNotification('snackbar-success', 'Успешно сохранен');
                this.router.navigate(['/users']);
              },
              error: (err) => {
                console.error(err);
                this.ntf.hideLoader();
              }
            })
          }
        });
    } else if (this.mode === FormMode.Edit) {
      this.ntf
        .confirmSwal('', 'Изменить пользователя?', 'question')
        .then((result) => {
          if (result.isConfirmed) {
            this.ntf.showLoader();
            this.adminService.editUser(body).subscribe({
              next: (res) => {
                this.ntf.hideLoader();
                this.ntf.showNotification('snackbar-success', 'Успешно изменен');
                this.router.navigate(['/users']);
              },
              error: (err) => {
                console.error(err);
                this.ntf.hideLoader();
              }
            })
          }
        })
    }
  }

  selectionRole(event: any) {
    if (event && event.value) {
      if (event.value === RoleTypes.ADMIN) {
        this.setDisableForControls(this.form.controls, 'functionRole');
        this.setDisableForControls(this.form.controls, 'layerRole');
      } else {
        this.setEnableForControls(this.form.controls, 'functionRole');
        this.setEnableForControls(this.form.controls, 'layerRole');
      }
    }
  }

  changePassword() {
    if (this.formPassword.invalid) {
      this.ntf.showNotification('snackbar-info', 'Заполните все поля');
      return;
    }
    const f = this.formPassword.value;
    if (f.newPassword !== f.newPassword2) {
      this.ntf.showNotification('snackbar-info', 'Пароли не совпадают')
      return
    }
    this.ntf.showLoader();
    this.adminService.changeUserPassword(this.userForm.id, { newPassword: f.newPassword }).subscribe({
      next: () => {
        this.ntf.hideLoader();
        this.matDialog.closeAll();
        this.ntf.showNotification('snackbar-success', 'Пароль успешно изменен')
      },
      error: (e) => {
        console.error(e);
        this.ntf.hideLoader();
      }
    })

  }

  getAllRoles() {
    this.adminService.getAllRoles().subscribe({
      next: (roles) => (this.roles = roles.filter(role => ![RoleTypes.LAW, RoleTypes.USER].includes(role))),
      error: (e) => console.error(e),
    });
  }

  getAllLayerRoles() {
    this.adminService.getAllLayerRoles().subscribe({
      next: (roles) => {
        this.layerRoles = roles;
      },
      error: (e) => console.error(e),
    });
  }

  getAllFunctionRoles() {
    this.adminService.getAllFunctionRoles().subscribe({
      next: (roles) => (this.functionRoles = roles),
      error: (e) => console.error(e),
    });
  }

  getListFunctions() {
    this.adminService.getListFunctions().subscribe({
      next: (lists) => this.functionLists = lists,
      error: (e) => console.error(e),
    });
  }

  getRoleName(code: string): string {
    return RoleNames[code];
  }

  getAllLayersTree() {
    this.adminService.getAllLayersTree().subscribe({
      next: (lists) => {
        this.adminService.admLayersList = lists;
      },
      error: (e) => console.error(e),
    });
  }

  isEmpty(obj: any) {
    return JSON.stringify(obj) === '{}'
  }
}
