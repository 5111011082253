<section class="content">
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="header">
            <h2><strong>Управление тарифами</strong></h2>
          </div>
          <div class="body"></div>
        </div>
      </div>
    </div>
  </div>
</section>
