<section class="content">
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header">
                        <h2><strong>События</strong></h2>
                        <ul class="rso-comments-header-container">
                            <li
                                class="filter-container"
                            >
                                <p
                                    *ngIf="isOperator && !isRegionOperator"
                                    style="margin: 0"
                                >
                                    Фильтровать по КАТО
                                </p>
                                <select
                                    *ngIf="isOperator && !isRegionOperator"
                                    [(ngModel)]="filterByKato"
                                    class="filter__select"
                                    (ngModelChange)="onSelectKato($event)"
                                >
                                    <option
                                        selected
                                        disabled
                                        [value]="notSelectedText"
                                        class="filter__option"
                                    >
                                        {{ notSelectedText }}
                                    </option>
                                    <option
                                        *ngFor="let kato of kato.katoList"
                                        [value]="kato.code"
                                        class="filter__option"
                                    >
                                        {{ kato.nameRu }}
                                    </option>
                                </select>
                                <p style="margin: 0">
                                    Фильтровать по статусу
                                </p>
                                <select
                                    [(ngModel)]="filterByStatus"
                                    class="filter__select"
                                    (change)="onSelectStatus()"
                                >
                                    <option
                                        selected
                                        disabled
                                        [value]="notSelectedText"
                                        class="filter__option"
                                    >
                                        {{ notSelectedText }}
                                    </option>
                                    <option
                                        *ngFor="let status of statusList"
                                        [value]="status.status"
                                        class="filter__option"
                                    >
                                        {{ status.name }}
                                    </option>
                                </select>
                                <button
                                    class="btn-tbl-reject-secondary"
                                    (click)="removeFilterValues()"
                                >
                                    <p style="margin: 0; font-weight: 600">
                                        Сброс
                                    </p>
                                </button>
                            </li>
                            <li class="rso-search-container">
                                <input
                                    matInput
                                    [(ngModel)]="searchValue"
                                    placeholder="Поиск..."
                                    class="rso-search-input"
                                >
                                <button
                                    class="rso-remove-search-btn"
                                    (click)="removeSearchValue()"
                                >
                                    <i-feather
                                        name="x"
                                    ></i-feather>
                                </button>
                                <button
                                    class="rso-search-btn-container"
                                    (click)="applyFilter()"
                                >
                                    <i-feather
                                        name="search"
                                        class="sidebarIcon"
                                    ></i-feather>
                                    <p style="margin: 0; font-weight: 600">
                                        Поиск
                                    </p>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="dataSource" class="w-100">
                            <ng-container matColumnDef="gid">
                                <th mat-header-cell *matHeaderCellDef>ID события</th>
                                <td mat-cell *matCellDef="let element">
                                    #{{ element.gid }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="category">
                                <th mat-header-cell *matHeaderCellDef>Тип события</th>
                                <td mat-cell *matCellDef="let element" style="width: 200px;">
                                    {{ eventCategoryName[element.category] }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="userInfo">
                                <th mat-header-cell *matHeaderCellDef>Пользователь</th>
                                <td mat-cell *matCellDef="let element" style="width: 200px;">
                                    <ul>
                                        <li *ngIf="element?.userInfo?.fio">
                                            • {{ element.userInfo.fio }}
                                        </li>
                                        <li *ngIf="element?.selectedVehicle">
                                            • {{ element.selectedVehicle }}
                                        </li>
                                        <li *ngIf="element?.userInfo?.phone">
                                            • {{ element.userInfo.phone }}
                                        </li>
                                    </ul>
                                </td>
                            </ng-container>

                            <ng-container
                                *ngIf="isOperator && !isRegionOperator"
                                matColumnDef="kato"
                            >
                                <th mat-header-cell *matHeaderCellDef>КАТО области</th>
                                <td mat-cell *matCellDef="let element" style="width: 200px;">
                                    {{ kato.getKatoItem(element.kato) }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="descriptionRu">
                                <th mat-header-cell *matHeaderCellDef>Описание события (текст, заполняемый пользователем)</th>
                                <td mat-cell *matCellDef="let element" style="width: 300px;">
                                    <p>
                                        {{ element.descriptionRu }}
                                    </p>
                                    <button
                                        class="btn-tbl-file-link-secondary"
                                        *ngIf="element.files"
                                        (click)="$event.stopPropagation()"
                                        (click)="downloadFile(element.files[0].uid)"
                                    >
                                        Ссылка на файл
                                    </button>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="sendPoint">
                                <th mat-header-cell *matHeaderCellDef>Локация на карте</th>
                                <td mat-cell *matCellDef="let element" style="width: 200px;">
                                    <button
                                        class="btn-tbl-map-secondary"
                                        (click)="$event.stopPropagation()"
                                        (click)="openMapHandler(element)"
                                    >
                                        Перейти на карту
                                    </button>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="employee">
                                <th mat-header-cell *matHeaderCellDef>Назначенный специалист ДЭУ</th>
                                <td mat-cell *matCellDef="let element">
                                    <p *ngIf="
                                        element.bitrixResponseDto
                                        && element.bitrixResponseDto[element.bitrixResponseDto.length - 1]?.employee
                                    ">
                                        {{
                                            element.bitrixResponseDto
                                            && "• " + element.bitrixResponseDto[element.bitrixResponseDto.length - 1]?.employee
                                        }}
                                    </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>Статус</th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    style="text-align: center; width: 200px;"
                                    class="pr-0"
                                >
                                    <div style="display: flex; flex-direction: column; align-items: flex-start">
                                        <button
                                            *ngIf="element.status"
                                            [class]="eventStatusDetails[element.status]?.style"
                                            [disabled]="![eventStatus.DRAFT, eventStatus.IN_PROGRESS].includes(element.status)"
                                            (click)="openChangeEventStatusModal(element)"
                                        >
                                            {{ eventStatusDetails[element.status]?.title }}
                                        </button>
                                        <button
                                            *ngIf="
                                                element.bitrixResponseDto
                                                && element.bitrixResponseDto[element.bitrixResponseDto.length - 1]?.file_links
                                                && [eventStatus.PROCESSED, eventStatus.REJECTED].includes(element.status)
                                            "
                                            class="btn-tbl-in-progress-secondary"
                                            (click)="$event.stopPropagation()"
                                            (click)="downloadFileBitrix(
                                            element.bitrixResponseDto
                                                && element.bitrixResponseDto[element.bitrixResponseDto.length - 1]?.file_links
                                            )"
                                        >
                                            Ссылка на файл
                                        </button>
                                        <p
                                            *ngIf="element.bitrixResponseDto
                                            && element.bitrixResponseDto[element.bitrixResponseDto.length - 1]?.resolution_text"
                                            style="margin: 10px 0"
                                        >
                                            {{
                                                element.bitrixResponseDto[element.bitrixResponseDto.length - 1]?.resolution_text
                                            }}
                                        </p>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns"
                            ></tr>
                        </table>
                        <mat-paginator
                            #paginator
                            [length]="totalElements"
                            [pageSize]="pageSize"
                            [pageIndex]="pageIndex"
                            (page)="handlePageEvent($event)"
                            hidePageSize
                            showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
