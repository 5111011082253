<div *ngIf="(isOperator || isSuperAdmin) && data.event.status === eventStatus.DRAFT" mat-dialog-content style="overflow: initial" class="notification-create__form-container">
    <form
        [formGroup]="formOperator"
        (ngSubmit)="sendEventStatus()"
        class="notification-create__container"
    >
        <div class="notification-create__field-container">
            <p style="margin: 0">
                Выберите Руководителя ДЭУ
            </p>
            <select
                class="filter__select"
                formControlName="empl"
                #selectEl
            >
                <option
                    *ngFor="let supervisor of supervisorList"
                    [value]="supervisor.empl_email"
                    class="filter__option"
                >
                    {{ supervisor.employee }}
                </option>
            </select>
        </div>

        <mat-dialog-actions
            class="notification-create__field-container"
            style="justify-content: center"
        >
            <button
                class="btn-tbl-reject-secondary"
                mat-dialog-close
            >
                Отменить
            </button>
            <button
                type="submit"
                class="btn-tbl-confirm-secondary"
                [mat-dialog-close]="true"
            >
                Отправить
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="isSupervisor" mat-dialog-content style="overflow: initial" class="notification-create__form-container">
    <form
        [formGroup]="formSupervisor"
        (ngSubmit)="sendEventStatus()"
        class="notification-create__container"
    >
        <div class="notification-create__field-container">
            <p style="margin: 0">
                Выберите Исполнителя ДЭУ
            </p>
            <select
                class="filter__select"
                formControlName="empl"
                #selectEl
            >
                <option
                    *ngFor="let specialist of specialistList"
                    [value]="specialist.empl_email"
                    class="filter__option"
                >
                    {{ specialist.employee }}
                </option>
            </select>
        </div>

        <mat-dialog-actions
            class="notification-create__field-container"
            style="justify-content: center"
        >
            <button
                class="btn-tbl-reject-secondary"
                mat-dialog-close
            >
                Отменить
            </button>
            <button
                type="submit"
                class="btn-tbl-confirm-secondary"
                [mat-dialog-close]="true"
            >
                Отправить
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="isSpecialist" mat-dialog-content style="overflow: initial" class="notification-create__form-container">
    <form
        [formGroup]="formSpecialist"
        (ngSubmit)="sendEventStatus()"
        class="notification-create__container"
    >
        <div class="notification-create__field-container">
            <p style="margin: 0">
                Выберите статус события
            </p>
            <select
                class="filter__select"
                formControlName="status"
                #selectEl
            >
                <option
                    *ngFor="let eventStatus of eventStatusList"
                    [value]="eventStatus.status"
                    class="filter__option"
                >
                    {{ eventStatus.title }}
                </option>
            </select>
        </div>

        <p style="margin: 0">
            Заполните резолюцию
        </p>
        <textarea
            formControlName="resolution_text"
            class="notification-create__field-container notification-create__input"
            placeholder="Введите текст..."
        ></textarea>

        <div class="notification-create__field-container">
            <label class="btn-tbl-draft-secondary">
                <input
                    type="file"
                    class="events-button-file"
                    (change)="uploadFiles($event)"
                />
                Выберите файл
            </label>
            <div>
                {{ this.formSpecialist.value.file_links.name }}
            </div>
        </div>

        <mat-dialog-actions
            class="notification-create__field-container"
            style="justify-content: center"
        >
            <button
                class="btn-tbl-reject-secondary"
                mat-dialog-close
            >
                Отменить
            </button>
            <button
                type="submit"
                class="btn-tbl-confirm-secondary"
                [mat-dialog-close]="true"
            >
                Подтвердить
            </button>
        </mat-dialog-actions>
    </form>
</div>
