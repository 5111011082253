<section class="content">
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="body">
            <div class="table-responsive">
              <div class="materialTableHeader">
                <div class="row">
                  <div class="col-8">
                    <ul class="header-buttons-left ml-0">
                      <li class="dropdown">
                        <h2>
                          <strong>Пользователи</strong>
                        </h2>
                      </li>
                      <li class="dropdown m-l-20" style="visibility: hidden">
                        <label for="search-input"
                          ><i class="material-icons search-icon"
                            >search</i
                          ></label
                        >
                        <input
                          placeholder="Поиск"
                          type="text"
                          #filter
                          class="browser-default search-field"
                          aria-label="Search box"
                        />
                      </li>
                    </ul>
                  </div>
                  <div class="col-4">
                    <ul class="header-buttons">
                      <li>
                        <div class="icon-button-demo">
                          <button
                            mat-mini-fab
                            color="primary"
                            [routerLink]="['/new_user']"
                          >
                            <mat-icon class="col-white">add</mat-icon>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div class="icon-button-demo">
                          <button
                            mat-mini-fab
                            color="primary"
                            (click)="refresh()"
                          >
                            <mat-icon class="col-white">refresh</mat-icon>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <table mat-table [dataSource]="dataSource" class="w-100">
                <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef>Фамилия</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.lastName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef>Имя</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.firstName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Email</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.email }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="isEnabled">
                  <th mat-header-cell *matHeaderCellDef>Статус</th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      class="label"
                      [ngClass]="
                        element.isEnabled ? 'label-success' : 'label-danger'
                      "
                    >
                      {{ element.isEnabled ? 'Активный' : 'Заблокирован' }}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef>Роль</th>
                  <td mat-cell *matCellDef="let element">
                    <ul
                      *ngFor="let role of element.authorities"
                      style="margin: 5px 0 0"
                    >
                      <li>
                        {{ getRoleName(role) }}
                      </li>
                    </ul>
                  </td>
                </ng-container>

                <ng-container matColumnDef="functionRole">
                  <th mat-header-cell *matHeaderCellDef>Функциональные роли</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.functionRole?.code ?? '--' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="layerRole">
                  <th mat-header-cell *matHeaderCellDef>
                    Роль по доступу к слоям
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.layerRole?.code ?? '--' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    style="text-align: center; width: 85px"
                    class="pr-0"
                  >
                    <div style="display: flex">
                      <button
                        mat-icon-button
                        color="accent"
                        class="btn-tbl-edit"
                        (click)="$event.stopPropagation()"
                        (click)="toUserEditForm(element)"
                      >
                        <mat-icon aria-label="Edit" class="col-white"
                          >edit</mat-icon
                        >
                      </button>
                      <button
                        mat-icon-button
                        color="accent"
                        class="btn-tbl-delete"
                        (click)="$event.stopPropagation()"
                        (click)="removeUser(element)"
                      >
                        <mat-icon aria-label="Delete" class="col-white"
                          >delete</mat-icon
                        >
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <ng-template #add>
  <div class="dlg_title">
    <h3 *ngIf="!isEditAccess" class="unselectable">Добавление пользователя</h3>
    <h3 *ngIf="isEditAccess" class="unselectable">Изменение пользователя</h3>
  </div>
  <form class="dialog_form" [formGroup]="form">
    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Фамилия </mat-label>
      <input
        matInput
        autocomplete="off"
        formControlName="lastName"
        required
        appearance="outline"
      />
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Имя </mat-label>
      <input matInput autocomplete="off" formControlName="firstName" required />
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Роль </mat-label>
      <mat-select
        required
        formControlName="role"
        [compareWith]="compareCategoryObjects"
      >
        <mat-option *ngFor="let role of roles" [value]="role">
          {{ getRoleName(role) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> E-mail </mat-label>
      <input matInput formControlName="email" autocomplete="off" required />
      <mat-error
        *ngIf="form.controls.email.invalid && form.controls.email.touched"
      >
        <span *ngIf="form.controls.email.errors?.required">
          Email не может быть пустым
        </span>
        <span *ngIf="form.controls.email.errors?.email">
          Введите корректный email
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Статус </mat-label>
      <mat-select
        required
        formControlName="isEnabled"
        [compareWith]="compareCategoryObjects"
      >
        <mat-option *ngFor="let status of isEnabled" [value]="status">
          {{ status.nameRu }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Пароль </mat-label>
      <input
        matInput
        type="password"
        autocomplete="off"
        required
        formControlName="password"
      />
      <mat-error
        *ngIf="form.controls.password.touched && form.controls.password.invalid"
        class="validation"
      >
        <span *ngIf="form.controls.password.errors?.required">
          Введите пароль
        </span>
        <span *ngIf="form.controls.password.errors?.minlength">
          Пароль должен быть не менее
          {{ form.controls.password.errors?.minlength.requiredLength }}
          символов. Сейчас он
          {{ form.controls.password.errors?.minlength.actualLength }}.
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Подтверждение </mat-label>
      <input
        matInput
        type="password"
        autocomplete="off"
        required
        formControlName="password2"
      />
      <mat-error
        *ngIf="
          form.controls.password2.touched && form.controls.password2.invalid
        "
        class="validation"
      >
        <span *ngIf="form.controls.password2.errors?.required">
          Введите пароль
        </span>
        <span *ngIf="form.controls.password2.errors?.minlength">
          Пароль должен быть не менее
          {{ form.controls.password2.errors?.minlength.requiredLength }}
          символов. Сейчас он
          {{ form.controls.password2.errors?.minlength.actualLength }}.
        </span>
      </mat-error>
    </mat-form-field>
  </form>

  <div
    class="btn_block"
    style="margin-top: 20px; display: flex; justify-content: end"
  >
    <button
      mat-button
      mat-stroked-button
      [mat-dialog-close]
      (click)="this.form.reset()"
    >
      Отмена
    </button>
    <button
      *ngIf="!isEditAccess"
      mat-raised-button
      color="primary"
      (click)="save()"
    >
      Сохранить
    </button>
    <button
      *ngIf="isEditAccess"
      mat-raised-button
      color="primary"
      (click)="edit()"
    >
      Изменить
    </button>
  </div>
</ng-template> -->
