import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigGlobalSearch, ApiConfigLayer } from '../../api.config';
import { Attribute, Layer } from '../shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GeoportalAdminService {
  constructor(private http: HttpClient) { }

  editFolder(body: any): Observable<any> {
    return this.http.post(`${ApiConfigLayer.api}/api/layers`, body);
  }

  removeFolder(body: any, layerName: string): Observable<any> {
    return this.http.delete(
      `${ApiConfigLayer.api}/api/metadata/layers${layerName}`,
      body
    );
  }

  getLayersByCriteria(criteria: string): Observable<any> {
    return this.http.get(
      `${ApiConfigLayer.api}/system/entity/bycriteria/layers/${criteria}`
    );
  }

  getLayers(): Observable<any> {
    return this.http.get(`${ApiConfigLayer.api}/api/layers`)
  }

  editLayers(id: number, body: Layer): Observable<any> {
    return this.http.put(`${ApiConfigLayer.api}/api/layers/${id}`, body)
  }

  getAvailableLayers(): Observable<any> {
    return this.http.get(`${ApiConfigLayer.api}/api/layers/tables`)
  }

  addLayer(body: any): Observable<any> {
    return this.http.post(`${ApiConfigLayer.api}/api/layers`, body);
  }

  deleteLayer(id: number): Observable<any> {
    return this.http.delete(`${ApiConfigLayer.api}/api/layers/${id}`)
  }

  getAttrsByLayerId(layerId: number): Observable<any> {
    return this.http.get(`${ApiConfigLayer.api}/public/layers/${layerId}/attrs`)
  }

  getAvailableAttrsByLayerId(layerId: number): Observable<any> {
    return this.http.get(`${ApiConfigLayer.api}/api/layers/${layerId}/columns`)
  }

  saveAttrsByLayerId(layerId: number, body: Attribute): Observable<any> {
    return this.http.post(`${ApiConfigLayer.api}/api/layers/${layerId}/attrs`, body);
  }

  editAttrsByLayerId(layerId: number, id: number, body: Attribute): Observable<any> {
    return this.http.put(`${ApiConfigLayer.api}/api/layers/${layerId}/attrs/${id}`, body);
  }

  deleteAttr(id: number): Observable<any> {
    return this.http.delete(`${ApiConfigLayer.api}/api/layers/attrs/${id}`);
  }

  identifyLayer(id: number, body: any): Observable<any> {
    return this.http.put(`${ApiConfigLayer.api}/api/layers/${id}`, body);
  }

  globalSearchIndex(body: any): Observable<any> {
    return this.http.post(`${ApiConfigGlobalSearch.api}/search/index`, body);
  }
}
