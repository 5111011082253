import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { UsersComponent } from './components/users/users.component';
import { IdentifyComponent } from './components/identify/identify.component';
import { FilterComponent } from './components/filter/filter.component';
import { NewUserComponent } from './components/users/pages/new-user/new-user.component';
import { UserItemComponent } from './components/users/pages/user-item/user-item.component';
import { LoggingLogComponent } from './components/logging-log/logging-log.component';
import { TariffManagementComponent } from './components/tariff-management/tariff-management.component';
import { RsoCommentsComponent } from './components/rso-comments/rso-comments.component';
import { EventsComponent } from './components/events/events.component';
import { EventsReportComponent } from "./components/events-report/events-report.component";
import { CloseRoadReportComponent } from "./components/close-road-report/close-road-report.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'tree-layer',
        loadChildren: () =>
          import('./components/tree-layer/tree-layer.module').then(
            (m) => m.TreeLayerModule
          ),
      },
      {
        path: 'identify',
        component: IdentifyComponent,
      },
      {
        path: 'filter',
        component: FilterComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'user/:id',
        component: UserItemComponent,
      },
      {
        path: 'new_user',
        component: NewUserComponent,
      },
      {
        path: 'logging',
        component: LoggingLogComponent,
      },
      {
        path: 'tariff',
        component: TariffManagementComponent,
      },
      {
        path: 'comments',
        component: RsoCommentsComponent,
      },
      {
        path: 'events',
        component: EventsComponent,
      },
      {
        path: 'events-report',
        component: EventsReportComponent,
      },
      {
        path: 'close-road-report',
        component: CloseRoadReportComponent,
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
