import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {BaseChartDirective} from "ng2-charts";
import {ChartDataSets, ChartOptions, ChartType} from "chart.js";
import {NotificationService} from "../../shared/services/notification.service";
import {AdminService} from "../../services/admin.service";
import {KatoService} from "../../shared/services/kato.service";
import {
  CloseRoadChartElementsPerGage,
  CloseRoadEventsChartDataType,
  CloseRoadEventsResponseType,
  CloseRoadEventsType
} from "./model/close-road-report.model";
import {PageEvent} from "@angular/material/paginator";
import {PaginationWayTypes} from "../events-report/models/events-report.model";

@Component({
  selector: 'app-close-road-report',
  templateUrl: './close-road-report.component.html',
  styleUrls: ['./close-road-report.component.sass']
})
export class CloseRoadReportComponent implements OnInit {
  closeRoadDisplayedColumns: string[] = [
    'gid',
    'name_ru',
    'user_name',
    'end_date',
    'start_date',
    'closed',
  ];

  paginationWayTypes = PaginationWayTypes;
  closeRoadChartData: CloseRoadEventsChartDataType[] = [];
  closeRoadDataTotalElements = null;
  closeRoadDataPageIndex = 1;
  closeRoadDataLastPageIndex = 1;
  closeRoadDataSource = new MatTableDataSource<CloseRoadEventsType>();
  filterByDateFrom: string | number | Date;
  filterByDateTo: string | number | Date;
  searchRoadValue = '';
  notSelectedText = 'Не выбрано';
  filterByKato = this.notSelectedText;

  closeRoadChartDataSets: ChartDataSets[] = [
    {
      data: [],
      backgroundColor: '#05bc64',
      hoverBackgroundColor: '#026534',
      barThickness: 'flex',
    },
    {
      data: [],
      backgroundColor: '#db2626',
      hoverBackgroundColor: '#db2626',
      barThickness: 'flex',
    },
  ];
  closeRoadChartLabels = [];
  closeRoadChartOptions: ChartOptions = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        ticks: {
          min: 0,
          stepSize: 5,
        }
      }],
    }
  };
  closeRoadChartType: ChartType = 'horizontalBar';

  @ViewChild('closeRoadChart')
  private closeRoadChartComponent: BaseChartDirective;

  pageSize = 10;
  pageIndex = 0;
  totalElements = null;
  pageEvent: PageEvent;

  constructor(
      private ntf: NotificationService,
      private adminService: AdminService,
      public kato: KatoService,
  ) {
  }

  ngOnInit() {
    this.kato.getKatoList();
    this.getCloseRoadEvents();
    this.getCloseRoadEventsChartData();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalElements = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getCloseRoadEvents();
  }

  onSelectKato(kato: string) {
    this.filterByKato = kato;
    this.getCloseRoadEventsChartData();
  }

  applySearchRoadValue() {
    this.pageIndex = 0;
    this.getCloseRoadEvents();
  }

  removeSearchRoadValue() {
    this.pageIndex = 0;
    this.searchRoadValue = null;
    this.getCloseRoadEvents();
  }

  onSelectDate(date: string, isDateFrom: boolean) {
    if(isDateFrom) {
      this.filterByDateFrom = date;
    } else {
      this.filterByDateTo = date;
    }
    this.getCloseRoadEventsChartData();
  }

  removeChartFilterValues() {
    this.filterByDateFrom = undefined;
    this.filterByDateTo = undefined;
    this.filterByKato = this.notSelectedText;
    this.getCloseRoadEventsChartData();
  }

  getDateFormat(date: string) {
    if (date === null) {
      return;
    }
    return new Date(date).toLocaleString('ru-RU');
  }

  getCloseRoadEvents() {
    this.ntf.showLoader();
    this.adminService.getCloseRoadEvents(this.searchRoadValue, this.pageIndex, this.pageSize)
      .subscribe({
        next: (data: CloseRoadEventsResponseType) => {
          const {content, totalElements} = data;

          const newData: CloseRoadEventsType[] = [
            {
              "gid": 1,
              "user_name": 'Жумабеков А.А.',
              "name_kz": "Астана - Темиртау",
              "name_en": "Астана - Темиртау",
              "start_date": '2023-01-01T16:37:22Z',
              "end_date": '2023-01-02T16:37:22Z',
              "name_ru": "Астана - Темиртау",
              "closed": 0
            },
            {
              "gid": 2,
              "user_name": 'Иванов И.И.',
              "name_kz": "Астана - Щучинск",
              "name_en": "Астана - Щучинск",
              "start_date": '2023-02-01T16:37:22Z',
              "end_date": '2023-02-02T16:37:22Z',
              "name_ru": "Астана - Щучинск",
              "closed": 0
            },
            {
              "gid": 3,
              "user_name": 'Алибеков А.А.',
              "name_kz": "Щучинск - Кокшетау",
              "name_en": "Щучинск - Кокшетау",
              "start_date": '2023-11-03T16:37:22Z',
              "end_date": '2023-11-03T16:37:22Z',
              "name_ru": "Щучинск - Кокшетау",
              "closed": 0
            },
            {
              "gid": 4,
              "user_name": 'Жумабеков А.А.',
              "name_kz": "Конаев - Талдыкорган",
              "name_en": "Конаев - Талдыкорган",
              "start_date": '2023-11-14T16:37:22Z',
              "end_date": '2023-11-15T16:37:22Z',
              "name_ru": "Конаев - Талдыкорган",
              "closed": 0
            },
            {
              "gid": 5,
              "user_name": 'Иванов И.И.',
              "name_kz": "Алматы - Конаев",
              "name_en": "Алматы - Конаев",
              "start_date": '2023-11-01T16:37:22Z',
              "end_date": '2023-11-01T16:37:22Z',
              "name_ru": "Алматы - Конаев",
              "closed": 0
            },
            {
              "gid": 6,
              "user_name": 'Алибеков А.А.',
              "name_kz": "Тараз - Кайнар",
              "name_en": "Тараз - Кайнар",
              "start_date": '2023-11-01T16:37:22Z',
              "end_date": '2023-11-01T16:37:22Z',
              "name_ru": "Тараз - Кайнар",
              "closed": 0
            },
            {
              "gid": 7,
              "user_name": 'Жумабеков А.А.',
              "name_kz": "Шымкент - Кызылорда",
              "name_en": "Шымкент - Кызылорда",
              "start_date": '2023-11-01T16:37:22Z',
              "end_date": '2023-11-01T16:37:22Z',
              "name_ru": "Шымкент - Кызылорда",
              "closed": 0
            },
            {
              "gid": 8,
              "user_name": 'Иванов И.И.',
              "name_kz": "Астана - Павлодар",
              "name_en": "Астана - Павлодар",
              "start_date": '2023-11-01T16:37:22Z',
              "end_date": '2023-11-01T16:37:22Z',
              "name_ru": "Астана - Павлодар",
              "closed": 0
            },
            {
              "gid": 25,
              "user_name": 'Алибеков А.А.',
              "name_kz": "\"Өскемен-Алтай-Катон-Қарағай-Рахман қайнарлары\" а/ж 46-66 км , Солтүстік с. бұрылысы -  Серебрянск бұрылысын телімі",
              "name_en": "Ust-Kamenogorsk-Altai-Katon-Karagay-Rakhmanovskie springs",
              "start_date": "2023-11-01T16:37:22Z",
              "end_date": '2023-11-01T16:37:22Z',
              "name_ru": "а/д \"Усть-Каменогорск-Алтай-Катон-Карагай-Рахмановские ключи\", км. 46-66, уч. пов. на с.Северный - пов. на г.Серебрянск",
              "closed": 2
            },
            {
              "gid": 26,
              "user_name": 'Жумабеков А.Ж.',
              "name_kz": "с. Абай - с Кабанбай",
              "name_en": "с. Абай - с Кабанбай",
              "start_date": '2023-11-01T16:37:22Z',
              "end_date": '2023-11-01T16:37:22Z',
              "name_ru": "с. Абай - с Кабанбай",
              "closed": 0
            },
          ];

          this.closeRoadDataSource = new MatTableDataSource(newData);
          this.totalElements = newData.length;
          this.ntf.hideLoader();
        },
        error: (error) => {
          this.ntf.hideLoader();
          console.error(error);
        },
      });
  }

  getCloseRoadEventsChartData() {
    this.ntf.showLoader();
    this.adminService.getCloseRoadEventsChartData(this.filterByDateFrom as string, this.filterByDateTo as string, this.filterByKato)
      .subscribe({
        next: (data: CloseRoadEventsChartDataType[]) => {
          const newData: CloseRoadEventsChartDataType[] = [
            { name_ru:'Астана - Щучинск', opened: 10, closed: 11 },
            { name_ru:'Алматы - Семей', opened: 15, closed: 14 },
            { name_ru:'Актобе - Костанай', opened: 24, closed: 23 },
            { name_ru:'Риддер - Усть-Каменогорск', opened: 28, closed: 28 },
            { name_ru:'Риддер - Усть-Каменогорск', opened: 28, closed: 28 },
            { name_ru:'Актобе - Костанай', opened: 24, closed: 23 },
            { name_ru:'Алматы - Семей', opened: 15, closed: 14 },
            { name_ru:'Астана - Щучинск', opened: 10, closed: 11 },
            { name_ru:'Актобе - Костанай', opened: 24, closed: 23 },
            { name_ru:'Алматы - Семей', opened: 15, closed: 14 },
            { name_ru:'Риддер - Усть-Каменогорск', opened: 28, closed: 28 },
            { name_ru:'Алматы - Семей', opened: 15, closed: 14 },
            { name_ru:'Астана - Щучинск', opened: 10, closed: 11 },
            { name_ru:'Алматы - Семей', opened: 15, closed: 14 },
            { name_ru:'Актобе - Костанай', opened: 24, closed: 23 },
            { name_ru:'Риддер - Усть-Каменогорск', opened: 28, closed: 28 },
            { name_ru:'Риддер - Усть-Каменогорск', opened: 28, closed: 28 },
            { name_ru:'Актобе - Костанай', opened: 24, closed: 23 },
            { name_ru:'Алматы - Семей', opened: 15, closed: 14 },
            { name_ru:'Астана - Щучинск', opened: 10, closed: 11 },
            { name_ru:'Актобе - Костанай', opened: 24, closed: 23 },
            { name_ru:'Алматы - Семей', opened: 15, closed: 14 },
            { name_ru:'Риддер - Усть-Каменогорск', opened: 28, closed: 28 },
            { name_ru:'Алматы - Семей', opened: 15, closed: 14 },
          ];

          this.closeRoadChartData = newData;
          this.closeRoadDataTotalElements = newData.length;
          this.closeRoadDataLastPageIndex = Math.ceil(newData.length / CloseRoadChartElementsPerGage);
          const closeRoadDataFiltered = newData.filter(
              (_, i) => i < CloseRoadChartElementsPerGage
          );

          this.setChartData(closeRoadDataFiltered);
          this.ntf.hideLoader();
        },
        error: (error) => {
          this.ntf.hideLoader();
          console.error(error);
        },
      });
  }

  getChartData(data: CloseRoadEventsChartDataType[]) {
    const chartDataOpened = [];
    const chartDataClosed = [];
    const chartLabels = ['', '', '', ''];

    data.forEach(({closed, opened, name_ru}, i) => {
      chartDataOpened.push(opened);
      chartDataClosed.push(closed);
      chartLabels[i] = name_ru;
    });

    return {
      chartDataOpened,
      chartDataClosed,
      chartLabels,
    }
  }

  paginationHandler(paginationWay: PaginationWayTypes) {
    let pageIndex = this.closeRoadDataPageIndex;
    if (paginationWay === PaginationWayTypes.START) {
      pageIndex = 1;
    } else if (paginationWay === PaginationWayTypes.PREV) {
      pageIndex -= 1;
    } else if (paginationWay === PaginationWayTypes.NEXT) {
      pageIndex += 1;
    } else if (paginationWay === PaginationWayTypes.LAST) {
      pageIndex = this.closeRoadDataLastPageIndex;
    }
    const closeRoadDataFiltered =
      this.closeRoadChartData.filter((_, i) =>
          i < pageIndex * CloseRoadChartElementsPerGage
          && i >= (pageIndex * CloseRoadChartElementsPerGage) - CloseRoadChartElementsPerGage
      );
    this.closeRoadDataPageIndex = pageIndex;
    this.setChartData(closeRoadDataFiltered);
  }

  setChartData(data: CloseRoadEventsChartDataType[]) {
    const { chartDataOpened, chartDataClosed, chartLabels } = this.getChartData(data);

    this.closeRoadChartComponent.chart.data.labels = chartLabels;
    this.closeRoadChartComponent.chart.data.datasets[0].data = chartDataOpened;
    this.closeRoadChartComponent.chart.data.datasets[1].data = chartDataClosed;
    this.closeRoadChartComponent.update();
  }
}
