import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiConfigDictionaries,
  ApiConfigEvents, ApiConfigFileStorage,
  ApiConfigLayer,
  ApiConfigUserData,
  ApiConfigUsers
} from '../../api.config';
import { User } from '../model/user';
import {SendNotificationBodyType} from "../components/notifications/model/notifications.model";
import {SendEventStatusType} from "../components/events/models/events.model";

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  admLayersList: any = [];

  constructor(private http: HttpClient) { }

  getAllUsers(): Observable<any> {
    return this.http.get(`${ApiConfigUsers.api}/user/all`);
  }

  getAllRoles(): Observable<any> {
    return this.http.get(`${ApiConfigUsers.api}/dictionary/authorities`);
  }

  getAllLayerRoles(): Observable<any> {
    return this.http.get(`${ApiConfigUsers.api}/layer-role/all`);
  }

  getAllFunctionRoles(): Observable<any> {
    return this.http.get(`${ApiConfigUsers.api}/function-role/all`);
  }

  getAllLayersTree(): Observable<any> {
    return this.http.get(`${ApiConfigLayer.api}/public/layers/tree`);
  }

  getListFunctions(): Observable<any> {
    return this.http.get(`${ApiConfigUsers.api}/dictionary/functions`);
  }

  saveFunctionRole(body: any): Observable<any> {
    return this.http.post(`${ApiConfigUsers.api}/function-role`, body);
  }

  saveLayerRole(body: any): Observable<any> {
    return this.http.post(`${ApiConfigUsers.api}/layer-role`, body);
  }

  deleteFunctionRole(id: number): Observable<any> {
    return this.http.delete(`${ApiConfigUsers.api}/function-role/${id}`);
  }

  deleteLayerRole(id: number): Observable<any> {
    return this.http.delete(`${ApiConfigUsers.api}/layer-role/${id}`);
  }

  removeUser(id: number): Observable<any> {
    return this.http.delete(`${ApiConfigUsers.api}/user/${id}`);
  }

  saveUser(body: User): Observable<any> {
    return this.http.post(`${ApiConfigUsers.api}/user`, body);
  }

  editUser(body: User): Observable<any> {
    return this.http.put(`${ApiConfigUsers.api}/user`, body);
  }

  disableUser(id: number): Observable<any> {
    return this.http.put(`${ApiConfigUsers.api}/user/disable/${id}`, null);
  }

  enableUser(id: number): Observable<any> {
    return this.http.put(`${ApiConfigUsers.api}/user/enable/${id}`, null);
  }

  changeUserPassword(id: number, body: any): Observable<any> {
    return this.http.put(`${ApiConfigUsers.api}/user/change-password/${id}`, body);
  }

  filterLogs(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfigLayer.api}/api/journal?page=${page}&size=${size}&sort=operationDate,desc`,
      body
    );
  }

  getRsoComments(page?: number, pageSize?: number, status?: string, rsoRowId?: number): Observable<any> {
    return this.http.get(`${ApiConfigUserData.api}/api/user-rate`, {
      params: {
        page: page || '',
        size: pageSize || '',
        status: status || '',
        rsoRowId: rsoRowId || '',
      }
    });
  }

  confirmRsoComment(id): Observable<any> {
    return this.http.put(`${ApiConfigUserData.api}/api/user-rate/moderate/${id}`, {});
  }

  rejectRsoComment(id): Observable<any> {
    return this.http.put(`${ApiConfigUserData.api}/api/user-rate/reject/${id}`, {});
  }

  deleteRsoComment(id): Observable<any> {
    return this.http.delete(`${ApiConfigUserData.api}/api/user-rate/${id}`);
  }

  getRsoName(id: number): Observable<any> {
    return this.http.get(`${ApiConfigLayer.api}/public/identify/layerobject`, {
      params: {
        layerName: 'worklyrs.lyr_ops',
        gid: id,
      },
    });
  }

  getEvents(page?: number, pageSize?: number, status?: string, kato?: string, text?: string, emplEmail?: string): Observable<any> {
    return this.http.get(`${ApiConfigEvents.api}/api/events`, {
      params: {
        page: page || '',
        size: pageSize || '',
        status: status || '',
        kato: kato || '',
        text: text || '',
        emplEmail: emplEmail || '',
      }
    });
  }

  getEventsStatus(params?: any): Observable<any> {
    return this.http.get(`${ApiConfigEvents.api}/api/events/report-by-status`, {params});
  }

  getCloseEvents(params?: any): Observable<any> {
    return this.http.get(`${ApiConfigEvents.api}/api/events/report-by-category`, {params});
  }

  getCloseRoadEvents(title?: string, page?: number, pageSize?: number, sort?: string): Observable<any> {
    return this.http.get(`${ApiConfigLayer.api}/api/road-section/weather/list`, {
      params: {
        title: title || '',
        page: page || '',
        size: pageSize || '',
        sort: sort || '',
      }
    });
  }

  getCloseRoadEventsChartData(dateFrom?: string, dateTo?: string, kato?: string): Observable<any> {
    return this.http.get(`${ApiConfigLayer.api}/api/road-section/weather/report-by-road`, {
      params: {
        dateFrom: dateFrom || '',
        dateTo: dateTo || '',
        kato: kato || '',
      }
    });
  }

  getKatoList(): Observable<any> {
    return this.http.get(`${ApiConfigDictionaries.api}/regions`);
  }

  getNotifications(topic: string, command: string, page?: number, pageSize?: number, sort?: string): Observable<any> {
    return this.http.get(`${ApiConfigEvents.api}/api/notifications`, {
      params: {
        topic,
        command,
        page: page || '',
        size: pageSize || '',
        sort: sort || '',
      }
    });
  }

  sendNotifications(body: SendNotificationBodyType): Observable<any> {
    return this.http.post(`${ApiConfigEvents.api}/api/notifications/send`, body);
  }

  sendEventsStatus(body: SendEventStatusType): Observable<any> {
    return this.http.post(`${ApiConfigEvents.api}/api/events/change-state`, body);
  }

  filesDownload(uid: string): Observable<any> {
    return this.http.get(`${ApiConfigFileStorage.api}/api/files/download/${uid}`,
        { responseType: "blob", observe: "response" }
    );
  }
}
