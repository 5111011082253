import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NotificationService } from '../../shared/services/notification.service';
import { AdminService } from '../../services/admin.service';
import { commentStatuses } from '../../model/constants';
import { map } from 'rxjs';
import { Comment, GetCommentsResponse, RsoLayerObject } from './models/comments.model';

@Component({
  selector: 'app-rso-comments',
  templateUrl: './rso-comments.component.html',
  styleUrls: ['./rso-comments.component.scss'],
})
export class RsoCommentsComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'userName',
    'rsoRowId',
    'rate',
    'comment',
    'actions',
  ];

  commentStatuses = commentStatuses;
  comments: Comment[] = [];
  dataSource = new MatTableDataSource<Comment>();
  searchValue = null;
  isShowOnlyCreated = false;
  isShowRejected = false;
  pageSize = 10;
  pageIndex = 0;
  totalElements = null;
  pageEvent: PageEvent;

  constructor(
      private ntf: NotificationService,
      private adminService: AdminService
  ) {
  }

  @ViewChild('paginator') paginator: MatPaginator;
  @Output() page: EventEmitter<PageEvent>;

  ngOnInit() {
    this.getRsoComments();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalElements = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getRsoComments();
  }

  applyFilter() {
    this.pageIndex = 0;
    this.ntf.showLoader();
    this.getRsoComments();
  }

  setShowOnlyCreated() {
    this.pageIndex = 0;
    this.isShowOnlyCreated = !this.isShowOnlyCreated;
    this.getRsoComments();
  }

  setShowRejected() {
    this.pageIndex = 0;
    this.isShowRejected = !this.isShowRejected;
    this.getRsoComments();
  }

  getRsoComments() {
    const status = this.isShowOnlyCreated
        ? commentStatuses.CREATED : this.isShowRejected
            ? commentStatuses.REJECTED : '';
    this.ntf.showLoader();
    this.adminService.getRsoComments(this.pageIndex, this.pageSize, status, this.searchValue).subscribe({
      next: (data: GetCommentsResponse) => {
        const {content, totalElements} = data;
        const comments = content.map(comment => ({...comment, rsoName: ''}));
        this.totalElements = totalElements;
        this.dataSource = new MatTableDataSource(comments);
        this.comments = comments;
        this.ntf.hideLoader();
        for (const comment of comments) {
          this.getRsoName(comment.rsoRowId);
        }
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  getRsoName(rsoRowId: number) {
    this.adminService.getRsoName(rsoRowId).subscribe({
      next: (rso: RsoLayerObject) => {
        this.comments = this.comments.map(
            comment => comment.rsoRowId === rsoRowId ? {...comment, rsoName: rso[0].name_ru} : comment
        );
        this.dataSource = new MatTableDataSource(this.comments);
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  removeSearchValue() {
    this.pageIndex = 0;
    this.searchValue = null;
    this.isShowOnlyCreated = false;
    this.getRsoComments();
  }

  confirmRsoComment(id: number) {
    this.ntf.showLoader();
    this.adminService.confirmRsoComment(id)
        .pipe(
            map(_ => {
              return this.comments.map(comment => comment.id === id ? {...comment, status: commentStatuses.ACTIVE} : comment);
            })
        )
        .subscribe({
            next: () => {
              this.getRsoComments();
              this.ntf.hideLoader();
            },
            error: (e) => {
              this.ntf.hideLoader();
              console.error(e);
            },
        });
  }

  rejectRsoComment(id: number) {
    this.ntf.showLoader();
    this.adminService.rejectRsoComment(id)
      .pipe(
          map(_ => {
            return this.comments.map(comment => comment.id === id ? {...comment, status: commentStatuses.REJECTED} : comment);
          })
      ).subscribe({
        next: () => {
          this.getRsoComments();
          this.ntf.hideLoader();
        },
        error: (e) => {
          this.ntf.hideLoader();
          console.error(e);
        },
      });
  }

  deleteRsoComment(id: number) {
    this.ntf.showLoader();
    this.adminService.deleteRsoComment(id).subscribe({
      next: () => {
        this.getRsoComments();
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }
}
