<section class="content">
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header">
                        <h2><strong>Анализ событий по типам и статусам</strong></h2>
                        <ul class="rso-comments-header-container">
                            <li
                                class="filter-container"
                            >
                                <p style="margin: 0">
                                    Фильтровать по периоду
                                </p>
                                <div class="events-report__filter-date-container">
                                    <div class="events-report__filter-date">
                                        <p style="margin: 0">
                                            Дата "с"
                                        </p>
                                        <input
                                            class="filter__select"
                                            type="date"
                                            [valueAsDate]="filterByDateFrom"
                                            (change)="onSelectDate($event.target.valueAsDate, true)"
                                        />
                                    </div>
                                    <div class="events-report__filter-date">
                                        <p style="margin: 0">
                                            Дата "по"
                                        </p>
                                        <input
                                            class="filter__select"
                                            type="date"
                                            [valueAsDate]="filterByDateTo"
                                            (change)="onSelectDate($event.target.valueAsDate, false)"
                                        />
                                    </div>
                                </div>
                                <p style="margin: 0">
                                    Фильтровать по области
                                </p>
                                <select
                                    [(ngModel)]="filterByKato"
                                    class="filter__select"
                                    (ngModelChange)="onSelectKato($event)"
                                >
                                    <option
                                        selected
                                        disabled
                                        [value]="notSelectedText"
                                        class="filter__option"
                                    >
                                        {{ notSelectedText }}
                                    </option>
                                    <option
                                        *ngFor="let kato of kato.katoList"
                                        [value]="kato.code"
                                        class="filter__option"
                                    >
                                        {{ kato.nameRu }}
                                    </option>
                                </select>
                                <button
                                    class="btn-tbl-reject-secondary"
                                    (click)="removeFilterValues()"
                                >
                                    <p style="margin: 0; font-weight: 600">
                                        Сброс
                                    </p>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="body events-report__charts-container">
                        <div class="events-report__chart">
                            <canvas baseChart #closeEventChart="base-chart"
                                [datasets]="closeEventChartData"
                                [labels]="closeEventChartLabels"
                                [options]="closeEventChartOptions"
                                [chartType]="closeEventChartType"
                            ></canvas>
                        </div>
                        <div class="events-report__chart">
                            <p
                                *ngIf="totalEvents"
                                style="margin: 0; text-align: center; font-weight: 600; font-size: 20px"
                            >
                                Всего событий в регионе: {{ totalEvents }}
                            </p>
                            <canvas baseChart #eventStatusChart="base-chart"
                                [datasets]="eventStatusChartData"
                                [labels]="eventStatusChartLabels"
                                [options]="eventStatusChartOptions"
                                [chartType]="eventStatusChartType"
                            ></canvas>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="header">
                        <h2><strong>Таблица "События по статусам"</strong></h2>
                    </div>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="eventsStatusDataSource" class="w-100">
                            <ng-container matColumnDef="kato">
                                <th mat-header-cell *matHeaderCellDef>Область</th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.kato !== countryText; then thenBlock else elseBlock"></div>
                                    <ng-template #thenBlock>
                                        {{ kato.getKatoItem(element.kato, true) }}
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        {{ element.kato }}
                                    </ng-template>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="createdCount">
                                <th mat-header-cell *matHeaderCellDef>В работе</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.createdCount }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="finishedCount">
                                <th mat-header-cell *matHeaderCellDef>Исполнено</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.finishedCount }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="rejectedCount">
                                <th mat-header-cell *matHeaderCellDef>Отклонено</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.rejectedCount }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="total">
                                <th mat-header-cell *matHeaderCellDef>Всего</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.total }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="eventsStatusDisplayedColumns"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: eventsStatusDisplayedColumns"
                            ></tr>
                        </table>
                        <div class="header events-report__pagination-container">
                            <button
                                class="events-report__pagination-button"
                                [ngClass]="{
                                    'events-report__pagination-button-disabled':
                                    eventsStatusDataPageIndex === 1
                                }"
                                [disabled]="eventsStatusDataPageIndex === 1"
                                (click)="paginationHandler(true, paginationWayTypes.START)"
                            >
                                <i-feather
                                    name="chevrons-left"
                                ></i-feather>
                            </button>
                            <button
                                class="events-report__pagination-button"
                                [ngClass]="{
                                    'events-report__pagination-button-disabled':
                                    eventsStatusDataPageIndex === 1
                                }"
                                [disabled]="eventsStatusDataPageIndex === 1"
                                (click)="paginationHandler(true, paginationWayTypes.PREV)"
                            >
                                <i-feather
                                    name="chevron-left"
                                ></i-feather>
                            </button>
                            <p style="margin: 0 5px;">
                                {{ eventsStatusDataPageIndex }} из {{ eventsStatusDataLastPageIndex }}
                            </p>
                            <button
                                class="events-report__pagination-button"
                                [ngClass]="{
                                    'events-report__pagination-button-disabled':
                                    eventsStatusDataPageIndex === eventsStatusDataLastPageIndex
                                }"
                                [disabled]="eventsStatusDataPageIndex === eventsStatusDataLastPageIndex"
                                (click)="paginationHandler(true, paginationWayTypes.NEXT)"
                            >
                                <i-feather
                                    name="chevron-right"
                                ></i-feather>
                            </button>
                            <button
                                class="events-report__pagination-button"
                                [ngClass]="{
                                    'events-report__pagination-button-disabled':
                                    eventsStatusDataPageIndex === eventsStatusDataLastPageIndex
                                }"
                                [disabled]="eventsStatusDataPageIndex === eventsStatusDataLastPageIndex"
                                (click)="paginationHandler(true, paginationWayTypes.LAST)"
                            >
                                <i-feather
                                    name="chevrons-right"
                                ></i-feather>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="header">
                        <h2><strong>Таблица "Закрытие события по типам"</strong></h2>
                    </div>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="closeEventDataSource" class="w-100">
                            <ng-container matColumnDef="kato">
                                <th mat-header-cell *matHeaderCellDef>Область</th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.kato !== countryText; then thenBlock else elseBlock"></div>
                                    <ng-template #thenBlock>
                                        {{ kato.getKatoItem(element.kato, true) }}
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        {{ element.kato }}
                                    </ng-template>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="carAccidentCount">
                                <th mat-header-cell *matHeaderCellDef>ДТП</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.carAccidentCount }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="animalOnRoadCount">
                                <th mat-header-cell *matHeaderCellDef>Выход животного на дорогу</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.animalOnRoadCount }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="foreignObjectsOnRoadCount">
                                <th mat-header-cell *matHeaderCellDef>Посторонние предметы на дороге</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.foreignObjectsOnRoadCount }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="backWeatherConditionsCount">
                                <th mat-header-cell *matHeaderCellDef>Плохие погодные условия или их последствия</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.backWeatherConditionsCount }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="emergencyStateOfTheRoadCount">
                                <th mat-header-cell *matHeaderCellDef>Аварийное состояние дороги или инфраструктуры</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.emergencyStateOfTheRoadCount }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="closeEventDisplayedColumns"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: closeEventDisplayedColumns"
                            ></tr>
                        </table>
                        <div class="header events-report__pagination-container">
                            <button
                                class="events-report__pagination-button"
                                [ngClass]="{
                                    'events-report__pagination-button-disabled':
                                    closeEventDataPageIndex === 1
                                }"
                                [disabled]="closeEventDataPageIndex === 1"
                                (click)="paginationHandler(false, paginationWayTypes.START)"
                            >
                                <i-feather
                                    name="chevrons-left"
                                ></i-feather>
                            </button>
                            <button
                                class="events-report__pagination-button"
                                [ngClass]="{
                                    'events-report__pagination-button-disabled':
                                    closeEventDataPageIndex === 1
                                }"
                                [disabled]="closeEventDataPageIndex === 1"
                                (click)="paginationHandler(false, paginationWayTypes.PREV)"
                            >
                                <i-feather
                                    name="chevron-left"
                                ></i-feather>
                            </button>
                            <p style="margin: 0 5px">
                                {{ closeEventDataPageIndex }} из {{ closeEventDataLastPageIndex }}
                            </p>
                            <button
                                class="events-report__pagination-button"
                                [ngClass]="{
                                    'events-report__pagination-button-disabled':
                                    closeEventDataPageIndex === closeEventDataLastPageIndex
                                }"
                                [disabled]="closeEventDataPageIndex === closeEventDataLastPageIndex"
                                (click)="paginationHandler(false, paginationWayTypes.NEXT)"
                            >
                                <i-feather
                                    name="chevron-right"
                                ></i-feather>
                            </button>
                            <button
                                class="events-report__pagination-button"
                                [ngClass]="{
                                    'events-report__pagination-button-disabled':
                                    closeEventDataPageIndex === closeEventDataLastPageIndex
                                }"
                                [disabled]="closeEventDataPageIndex === closeEventDataLastPageIndex"
                                (click)="paginationHandler(false, paginationWayTypes.LAST)"
                            >
                                <i-feather
                                    name="chevrons-right"
                                ></i-feather>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
