import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {NotificationService} from "../../../shared/services/notification.service";
import {AdminService} from "../../../services/admin.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {
  BitrixResponseDtoType,
  EventStatus,
  EventStatusSpecialistList,
  EventType,
  EventUploadFileType,
  SpecialistList,
  SupervisorList
} from "../models/events.model";
import {RoleTypes} from "../../../model/constants";

@Component({
  selector: 'app-event-change-status',
  templateUrl: './event-change-status.component.html',
  styleUrls: ['./event-change-status.component.sass']
})
export class EventChangeStatusComponent implements OnInit {
  formOperator = new FormGroup({
    empl: new FormControl(''),
  })
  formSupervisor = new FormGroup({
    empl: new FormControl(''),
  })
  formSpecialist = new FormGroup({
    status: new FormControl(EventStatusSpecialistList[0].status),
    file_links: new FormControl({}),
    resolution_text: new FormControl(''),
  })

  eventStatus = EventStatus;
  supervisorList = SupervisorList[this.data.event.kato];
  specialistList = SpecialistList[this.data.event.kato];
  eventStatusList = EventStatusSpecialistList;
  isSuperAdmin = false;
  isOperator = false;
  isSupervisor = false;
  isSpecialist = false;

  constructor(
      private ntf: NotificationService,
      private adminService: AdminService,
      @Inject(MAT_DIALOG_DATA) public data: {
        event: EventType,
        userRole: string[],
      }
  ) {}

  ngOnInit(): void {
    const {userRole} = this.data;
    if (userRole.includes(RoleTypes.SUPERADMIN)) {
      this.isSuperAdmin = true;
    } else if (userRole.includes(RoleTypes.ROD_OPERATOR)) {
      this.isOperator = true;
    } else if (userRole.includes(RoleTypes.ROD_SUPERVISOR)) {
      this.isSupervisor = true;
    } else {
      this.isSpecialist = true;
    }
  }

  uploadFiles(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.convertFileToBase64(file, (file64: string) => {
      const formData: EventUploadFileType = {
        url: file64,
        name: file.name,
      }
      this.formSpecialist.patchValue({
        file_links: formData,
      });
    })
  }

  convertFileToBase64 = (file: File, callBack: (value: string) => void) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const file64 = reader.result as string
      callBack(file64)
    }
    reader.readAsDataURL(file)
  }

  sendEventStatus() {
    this.ntf.showLoader();
    const defaultBitrixData = [{
      sapar_tx: '',
      event_id: this.data.event.gid,
      resolution_text: '',
      status: EventStatus.DRAFT,
      employee: '',
      empl_phone: '',
      empl_rod: '',
      empl_email: '',
      file_links: '',
    }];
    const bitrixData: BitrixResponseDtoType[] = this.data.event.bitrixResponseDto && this.data.event.bitrixResponseDto.length
        ? this.data.event.bitrixResponseDto
        : defaultBitrixData;
    let body: BitrixResponseDtoType = bitrixData[bitrixData.length - 1];

    if ((this.isOperator || this.isSuperAdmin) && this.data.event.status === this.eventStatus.DRAFT) {
      body.empl_email = this.formOperator.value.empl;
      body.employee = this.supervisorList.find(
          (empl: {empl_email: string, employee: string}) => empl.empl_email === this.formOperator.value.empl
      ).employee;
      body.status = EventStatus.IN_PROGRESS;
    } else if (this.isSupervisor) {
      body.empl_email = this.formSupervisor.value.empl;
      body.employee = this.specialistList.find(
          (empl: {empl_email: string, employee: string}) => empl.empl_email === this.formSupervisor.value.empl
      ).employee;
      body.status = EventStatus.IN_PROGRESS;
    } else if (this.isSpecialist) {
      const f = this.formSpecialist.value;
      body.resolution_text = f.resolution_text;
      body.file_links = f.file_links.url;
      body.status = f.status;
      console.log("f.resolution_text, ", f.resolution_text)
    }
    console.log("body, ", body)
    this.adminService.sendEventsStatus(body).subscribe({
      next: () => {
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }
}
