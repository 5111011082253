import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Pagination } from 'src/app/model/constants';
import { User } from 'src/app/model/user';
import { AdminService } from 'src/app/services/admin.service';
import { GeoportalAdminService } from 'src/app/services/geoportal-admin.service';
import { Layer } from 'src/app/shared/interfaces';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as moment from "moment";

@Component({
  selector: 'app-logging-log',
  templateUrl: './logging-log.component.html',
  styleUrls: ['./logging-log.component.sass']
})
export class LoggingLogComponent implements OnInit {
  displayedColumns: string[] = [
    'position',
    'userInfo',
    'operationDate',
    'operation',
    'layerInfo',
    'objectId'
  ];

  data: any = [];
  dataSource: any = new MatTableDataSource(this.data);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  form: FormGroup;
  users: User[] = [];
  layers: Layer[] = [];
  operations = [
    { "code": "INSERT", "nameRu": "Добавление" },
    { "code": "UPDATE", "nameRu": "Обновление" },
    { "code": "DELETE", "nameRu": "Удаление" }
  ]

  totalCount: number = 0;
  page: number = Pagination.DEFAULT_PAGE;
  size: number = Pagination.DEFAULT_PAGE_SIZE;
  DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";

  constructor(
    private adminService: AdminService,
    private geoportalAdminService: GeoportalAdminService,
    private ntf: NotificationService
  ) {
    this.form = this.initForm();
  }

  ngOnInit(): void {
    this.getAllUsers();
    this.getLayers();
  }

  initForm() {
    return new FormGroup({
      userId: new FormControl(null),
      operationDateFrom: new FormControl(null),
      operationDateTo: new FormControl(null),
      operation: new FormControl(null),
      layerId: new FormControl(null),
      objectId: new FormControl(null),
    });
  }

  getAllUsers() {
    this.ntf.showLoader();
    this.adminService.getAllUsers().subscribe({
      next: (users) => {
        this.ntf.hideLoader();
        this.users = users;
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  getLayers() {
    this.ntf.showLoader();
    this.geoportalAdminService.getLayers().subscribe({
      next: (layers: Layer[]) => {
        this.ntf.hideLoader();
        this.layers = layers;
      },
      error: (err) => {
        console.error(err);
        this.ntf.hideLoader();
      },
    })
  }

  search(event?: any) {
    if (this.form.invalid) {
      this.ntf.showNotification('snackbar-info', 'Выберите магазин');
      return;
    }
    this.ntf.showLoader();
    const f = this.form.value;
    const body = this.form.value;
    body.operationDateFrom = f.operationDateFrom ? this.utc(f.operationDateFrom.setHours(0, 0, 0, 0)) : undefined;
    body.operationDateTo = f.operationDateTo ? this.utc(f.operationDateTo.setHours(23, 59, 59, 999)) : undefined;

    this.size = event ? event.pageSize : Pagination.DEFAULT_PAGE_SIZE;
    this.page = event ? event.pageIndex : Pagination.DEFAULT_PAGE;
    this.adminService.filterLogs(body, this.page, this.size)
      .subscribe({
        next: (data) => {
          this.ntf.hideLoader();
          this.data = data.content;
          this.data.forEach(el => {
            el.operationDate = this.getDateTime_forHTMLElement(el.operationDate);
          });
          this.dataSource = new MatTableDataSource(this.data);
          this.dataSource.sort = this.sort;
          this.totalCount = data.totalElements;
        },
        error: (error) => {
          this.ntf.hideLoader();
          console.error(error);
        },
      });
  }

  private getDateTime_forHTMLElement = (dateTime: string): string => {
    if (dateTime) {
      var datetime = moment.utc(this.getValid(dateTime), "DD.MM.YYYY HH:MM:SS");
      return dateTime;
    } else return null;
  };

  private getValid(dateTime: string): string {
    let array = dateTime.split(".")
    if (array.length == 2) {
      let ms = Number(array[1].slice(0, array[1].length - 1))
      return array[0] + "." + ms.toString();
    } else {
      return dateTime;
    }
  }

  private utc(dataTime): string {
    var utc = moment(dataTime).utc().format(this.DATE_TIME_FORMAT).toString();
    utc += ".000Z";
    return utc;
  }

  clear() {
    this.form.reset();
    this.data = [];
    this.dataSource = new MatTableDataSource(this.data);
  }
}
