import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormMode } from 'src/app/model/constants';
import { GeoportalAdminService } from 'src/app/services/geoportal-admin.service';
import { Layer } from 'src/app/shared/interfaces';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-identify',
  templateUrl: './identify.component.html',
  styleUrls: ['./identify.component.sass'],
})
export class IdentifyComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'nameRu',
    'nameKz',
    'layerName',
    'identify',
  ];

  mode: FormMode;

  data: Layer[] = [];
  dataSource: any = new MatTableDataSource(this.data);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private ntf: NotificationService, private geoportalAdminService: GeoportalAdminService) {
    this.mode = FormMode.View;
  }

  ngOnInit(): void {
    this.search();
  }

  search() {
    this.ntf.showLoader();
    this.geoportalAdminService.getLayers().subscribe({
      next: (res: Layer[]) => {
        this.ntf.hideLoader();
        this.data = res.sort((a, b) => a.id - b.id);
        this.dataSource = new MatTableDataSource(this.data.filter(el => el.layerName));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      error: (err) => {
        console.error(err);
        this.ntf.hideLoader();
      },
    })
  }

  identify(element: Layer, identify: boolean) {
    if (!element) return;
    this.ntf.showLoader();
    this.geoportalAdminService.identifyLayer(element.id, { ...element, identify: identify }).subscribe({
      next: () => {
        this.ntf.hideLoader();
        this.search();
      },
      error: (err) => {
        console.error(err);
        this.ntf.hideLoader();
      }
    })
  }

  toEdit() {
    this.mode = FormMode.Edit;
  }

  refresh() {
    this.ntf.showLoader();
    this.dataSource = new MatTableDataSource([]);
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.ntf.hideLoader();
    }, 800);
  }
}
