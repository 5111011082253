<section class="content">
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header">
                        <h2><strong>Push-уведомления</strong></h2>
                        <ul class="rso-comments-header-container">
                            <li
                                class="filter-container"
                            >
                                <p style="margin: 0">
                                    Фильтровать по типу
                                </p>
                                <select
                                    [(ngModel)]="filterByType"
                                    class="filter__select"
                                    style="margin-right: 100px"
                                    (ngModelChange)="onSelectType($event)"
                                >
                                    <option
                                        *ngFor="let notifType of notificationTypes"
                                        [value]="notifType.command"
                                        class="filter__option"
                                    >
                                        {{ notifType.name }}
                                    </option>
                                </select>
                                <button
                                    class="btn-tbl-map-secondary"
                                    (click)="openCreateNotificationModal()"
                                >
                                    <p style="margin: 0; font-weight: 600">
                                        Создать
                                    </p>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="dataSource" class="w-100">
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef>ID уведомления</th>
                                <td mat-cell *matCellDef="let element">
                                    #{{ element.id }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="createdDate">
                                <th mat-header-cell *matHeaderCellDef>Дата и время отправки</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ getDate(element.createdDate) }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="messageRu">
                                <th mat-header-cell *matHeaderCellDef>Текст уведомления</th>
                                <td mat-cell *matCellDef="let element">
                                    <h6 style="font-weight: 600">
                                        {{ element.payload.headerRu }}
                                    </h6>
                                    <p>
                                        {{ element.payload.messageRu }}
                                    </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="licensePlates">
                                <th mat-header-cell *matHeaderCellDef>Пользователи-адресаты</th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.payload?.licensePlates?.length; then thenBlock else elseBlock"></div>
                                    <ng-template #thenBlock>
                                        <ul>
                                            <li
                                                *ngFor="let grnz of element.payload.licensePlates"
                                            >
                                                • {{ grnz }}
                                            </li>
                                        </ul>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        <p style="margin: 0">
                                            ВСЕ
                                        </p>
                                    </ng-template>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns"
                            ></tr>
                        </table>
                        <mat-paginator
                            #paginator
                            [length]="totalElements"
                            [pageSize]="pageSize"
                            [pageIndex]="pageIndex"
                            (page)="handlePageEvent($event)"
                            hidePageSize
                            showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
