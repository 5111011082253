<section class="content">
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="header">
            <h2>
              <strong>Управление поиском</strong>
            </h2>
          </div>
          <div class="body">
            <mat-tab-group>
              <mat-tab label="Настройки поиска">
                <div class="row m-t-40">
                  <div class="col-6">
                    <form [formGroup]="form">
                      <mat-form-field
                        class="example-full-width"
                        appearance="outline"
                      >
                        <mat-select
                          formControlName="layerName"
                          placeholder="Выбрать слой"
                          (selectionChange)="selectionLayer($event)"
                        >
                          <mat-option>--</mat-option>
                          <mat-option
                            *ngFor="let layerName of layerNames"
                            [value]="layerName"
                          >
                            {{ layerName.nameRu }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </form>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="body table-responsive">
                    <table
                      mat-table
                      [dataSource]="dataSource"
                      class="w-100"
                      matSort
                    >
                      <ng-container matColumnDef="nameRu">
                        <th mat-header-cell *matHeaderCellDef>
                          Наименование (рус)
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.nameRu }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="nameKz">
                        <th mat-header-cell *matHeaderCellDef>
                          Наименование (каз)
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.nameKz }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="sysname">
                        <th mat-header-cell *matHeaderCellDef>
                          Наименование атрибута в базе
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.sysname }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="search">
                        <th mat-header-cell *matHeaderCellDef>search</th>
                        <td mat-cell *matCellDef="let element">
                          <mat-checkbox
                            #myCheckbox
                            [checked]="element.search"
                            class="example-margin"
                            (click)="
                              changeSearchAttr(
                                element,
                                !element.search,
                                'search'
                              )
                            "
                          >
                          </mat-checkbox>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="searchResultRu">
                        <th mat-header-cell *matHeaderCellDef>
                          searchResultRu
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-checkbox
                            #myCheckbox
                            [checked]="element.searchResultRu"
                            class="example-margin"
                            (click)="
                              changeSearchAttr(
                                element,
                                !element.searchResultRu,
                                'searchResultRu'
                              )
                            "
                          >
                          </mat-checkbox>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="searchResultKz">
                        <th mat-header-cell *matHeaderCellDef>
                          searchResultKz
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-checkbox
                            #myCheckbox
                            [checked]="element.searchResultKz"
                            class="example-margin"
                            (click)="
                              changeSearchAttr(
                                element,
                                !element.searchResultKz,
                                'searchResultKz'
                              )
                            "
                          >
                          </mat-checkbox>
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Настройка индексов слоев">
                <div class="body table-responsive">
                  <mat-paginator
                    [pageSizeOptions]="[20, 50, 100]"
                    aria-label="Select page of users"
                  ></mat-paginator>
                  <table
                    mat-table
                    [dataSource]="dataSourceLayer"
                    class="w-100"
                    matSort
                  >
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        #
                      </th>
                      <td mat-cell *matCellDef="let element; let idx = index">
                        {{ element.id }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="nameRu">
                      <th mat-header-cell *matHeaderCellDef>Наименование</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.nameRu }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="nameKz">
                      <th mat-header-cell *matHeaderCellDef>
                        Наименование (каз)
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.nameKz }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="layerName">
                      <th mat-header-cell *matHeaderCellDef>
                        Наименование слоя
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.layerName }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="index">
                      <th mat-header-cell *matHeaderCellDef>Индексировать</th>
                      <td mat-cell *matCellDef="let element">
                        <span
                          class="font-bold col-cyan"
                          style="cursor: pointer"
                          (click)="index(element)"
                        >
                          Индексировать
                        </span>
                      </td>
                    </ng-container>

                    <tr
                      mat-header-row
                      *matHeaderRowDef="displayedLayerColumns"
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedLayerColumns"
                    ></tr>
                  </table>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
