<div mat-dialog-content style="overflow: initial" class="notification-create__form-container">
    <form
        [formGroup]="form"
        (ngSubmit)="createNotification()"
        class="notification-create__container"
    >
        <div class="notification-create__field-container">
            <p style="margin: 0">
                Выберите тип
            </p>
            <select
                class="filter__select"
                formControlName="filterByType"
                #selectEl
                (change)="selectFilterByType(selectEl.value)"
            >
                <option
                    *ngFor="let notifType of notificationTypesFilter"
                    [value]="notifType.command"
                    class="filter__option"
                >
                    {{ notifType.name }}
                </option>
            </select>
        </div>

        <div
            class="notification-create__field-container"
        >
            <div
                *ngFor="let licensePlate of form?.value?.licensePlates; index as i"
                class="notification-create__field-container"
                style="gap: 0"
            >
                <p class="notification-create__licenseplate">
                    {{ licensePlate }}
                </p>
                <button
                    type="button"
                    class="notification-create__button"
                    (click)="deleteLicensePlate(i)"
                >
                    <i-feather
                        size="12"
                        name="x"
                    ></i-feather>
                </button>
            </div>
        </div>

        <div
            *ngIf="form.value.filterByType === notificationTypes.PERSONAL"
            class="notification-create__field-container"
        >
            <input
                type="text"
                formControlName="licensePlateInputValue"
                placeholder="Выберите пользователей"
                class="notification-create__input"
                [ngClass]="{
                    'notification-create__input-error': licensePlateInputValue!.invalid &&
                    (licensePlateInputValue!.dirty || licensePlateInputValue!.touched),
                    'notification-create__input-success': licensePlateInputValue!.valid
                }"
            />
            <button
                type="button"
                class="btn-tbl-draft-secondary"
                (click)="addLicensePlate()"
                [disabled]="licensePlateInputValue?.invalid"
            >
                Добавить
            </button>
        </div>
        <div
            *ngIf="licensePlates?.invalid || licensePlateInputValue!.invalid"
            class="notification-create__error"
        >
            <div *ngIf="licensePlates?.errors">Добавьте минимум 1 ГРНЗ</div>
            <div *ngIf="licensePlateInputValue!.errors?.['pattern']">ГРНЗ не соответсвует шаблону (пример: 123ABC01)</div>
        </div>

        <textarea
            formControlName="headerRu"
            class="notification-create__field-container notification-create__input"
            placeholder="Введите текст заголовка (до 50 символов)"
            maxlength="50"
            [ngClass]="{
                'notification-create__input-error':
                headerRu!.invalid && (headerRu!.dirty || headerRu!.touched),
                'notification-create__input-success': headerRu!.valid
            }"></textarea>
        <div
            *ngIf="headerRu!.invalid &&
            (headerRu!.dirty || headerRu!.touched)"
             class="notification-create__error"
        >
            <div *ngIf="headerRu!.errors?.['required']">Заголовок обязателен</div>
        </div>

        <textarea
            formControlName="messageRu"
            class="notification-create__field-container notification-create__input"
            [ngClass]="{
                'notification-create__input-error':
                messageRu!.invalid && (messageRu!.dirty || messageRu!.touched),
                'notification-create__input-success': messageRu!.valid
            }"
            placeholder="Введите основной текст (до 150 символов)"
            maxlength="150"></textarea>
        <div
            *ngIf="messageRu!.invalid &&
            (messageRu!.dirty || messageRu!.touched)"
            class="notification-create__error"
        >
            <div *ngIf="messageRu!.errors?.['required']">Основной текст обязателен</div>
        </div>

        <mat-dialog-actions
            class="notification-create__field-container"
            style="justify-content: center"
        >
            <button
                class="btn-tbl-reject-secondary"
                mat-dialog-close
            >
                Отменить
            </button>
            <button
                type="submit"
                class="btn-tbl-confirm-secondary"
                [mat-dialog-close]="true"
                [disabled]="form.invalid"
            >
                Отправить
            </button>
        </mat-dialog-actions>
    </form>
</div>
