import {Component} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationTypes, NotificationTypesFilter, SendNotificationBodyType} from "../model/notifications.model";
import {NotificationService} from "../../../shared/services/notification.service";
import {AdminService} from "../../../services/admin.service";

@Component({
  selector: 'app-notification-create',
  templateUrl: './notification-create.component.html',
  styleUrls: ['./notification-create.component.sass'],
})
export class NotificationCreateComponent {
  form = new FormGroup({
    filterByType: new FormControl(NotificationTypes.COMMON),
    licensePlateInputValue: new FormControl('', {
      validators: [
          Validators.pattern(/[0-9]{3}[A-Z]{3}[0-2]{1}[0-9]{1}/gm),
          Validators.minLength(1),
      ]
    }),
    headerRu: new FormControl('', {
      validators: [Validators.required],
    }),
    messageRu: new FormControl('', {
      validators: [Validators.required],
    }),
  })

  notificationTypesFilter = NotificationTypesFilter;
  notificationTypes = NotificationTypes;

  constructor(
      private ntf: NotificationService,
      private adminService: AdminService,
      private fb: FormBuilder,
  ) {}

  get licensePlates() {
    return this.form.controls["licensePlates"] as FormArray;
  }

  get headerRu() {
    return this.form.controls["headerRu"] as FormControl;
  }

  get messageRu() {
    return this.form.controls["messageRu"] as FormArray;
  }

  get licensePlateInputValue() {
    return this.form.controls["licensePlateInputValue"] as FormControl;
  }

  minLengthArray(min: number) {
    return (c: AbstractControl): {[key: string]: any} => {
      if (c.value.length >= min)
        return null;
      return { MinLengthArray: true};
    }
  }

  selectFilterByType(value: string) {
    if (value === this.notificationTypes.PERSONAL) {
      this.form.addControl('licensePlates', this.fb.array(
      [],
      this.minLengthArray(1),
      ))
    } else {
      if(this.licensePlates) {
        this.form.removeControl('licensePlates');
      }
    }
  }

  addLicensePlate() {
    (<FormArray>this.form.controls["licensePlates"]).push(new FormControl(this.form.value.licensePlateInputValue));
    this.licensePlateInputValue.setValue('');
  }

  deleteLicensePlate(index: number) {
    this.licensePlates.removeAt(index);
  }

  createNotification() {
    this.ntf.showLoader();
    const f = this.form.value;
    const body: SendNotificationBodyType = {
      headerRu: f.headerRu,
      messageRu: f.messageRu,
      licensePlates: f.licensePlates?.length ? f.licensePlates : [],
    }
    this.adminService.sendNotifications(body).subscribe({
      next: () => {
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }
}
