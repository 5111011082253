<section class="content">
  <button
    mat-raised-button
    type="button"
    color="primary"
    [routerLink]="['/users']"
  >
    <a class="fas fa-arrow-left" style="color: #fff; margin-right: 10px"></a>
    Назад
  </button>
  <div style="padding-top: 20px">
    <app-user-form [userForm]="{}"></app-user-form>
  </div>
</section>
