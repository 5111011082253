const ApiConfigAdminLayersApi = 'http://192.168.122.132:8830';
const ApiConfigGlobalApi = 'http://192.168.122.132:8850';
const ApiConfigAdminUsersApi = 'http://192.168.122.132:8820';
const ApiConfigUserDataApi = 'http://192.168.122.132:8870';
const ApiConfigEventsApi = 'http://192.168.122.132:8865';
const ApiConfigDictionariesApi = 'http://192.168.122.132:8844';
const ApiConfigFileStorageApi = 'http://192.168.122.132:8810';

export const ApiConfigLayer = {
  api: '/adm-layers',
  // api: ApiConfigAdminLayersApi,
  client_id: 'geoportal',
  client_secret: 'geoportal',
};
export const ApiConfigGlobalSearch = {
  api: '/global-search',
  // api: ApiConfigGlobalApi,
  client_id: 'geoportal',
  client_secret: 'geoportal',
};
export const ApiConfigUsers = {
  api: '/adm-users',
  // api: ApiConfigAdminUsersApi,
  client_id: 'geoportal',
  client_secret: 'geoportal',
};
export const ApiConfigUserData = {
  api: '/user-data',
  // api: ApiConfigUserDataApi,
  client_id: 'user-data',
  client_secret: 'user-data',
};
export const ApiConfigEvents = {
  api: '/events',
  // api: ApiConfigEventsApi,
  client_id: 'user-data',
  client_secret: 'user-data',
};
export const ApiConfigDictionaries = {
  api: '/dictionaries',
  // api: ApiConfigDictionariesApi,
  client_id: 'dictionaries',
  client_secret: 'dictionaries',
};
export const ApiConfigFileStorage = {
  api: '/file-storage',
  // api: ApiConfigFileStorageApi,
  client_id: 'file-storage',
  client_secret: 'file-storage',
};
