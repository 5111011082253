import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {
  CloseEventsType,
  CloseRoadEvents,
  CountryText,
  EventsStatusTableType,
  EventsStatusType,
  EventStatuses,
  KatoKazakhstan,
  PaginationWayTypes
} from "./models/events-report.model";
import {AdminService} from "../../services/admin.service";
import {NotificationService} from "../../shared/services/notification.service";
import {KatoService} from "../../shared/services/kato.service";
import {ChartDataSets, ChartOptions, ChartType} from "chart.js";
import {BaseChartDirective} from "ng2-charts";

@Component({
  selector: 'app-events-report',
  templateUrl: './events-report.component.html',
  styleUrls: ['./events-report.component.sass'],
})
export class EventsReportComponent implements OnInit {
  eventsStatusDisplayedColumns: string[] = [
    'kato',
    'createdCount',
    'finishedCount',
    'rejectedCount',
    'total',
  ];
  closeEventDisplayedColumns: string[] = [
    'kato',
    'carAccidentCount',
    'animalOnRoadCount',
    'foreignObjectsOnRoadCount',
    'backWeatherConditionsCount',
    'emergencyStateOfTheRoadCount',
  ];

  paginationWayTypes = PaginationWayTypes;
  eventsStatusData: EventsStatusTableType[] = [];
  eventsStatusDataTotalElements = null;
  eventsStatusDataPageIndex = 1;
  eventsStatusDataLastPageIndex = 1;
  eventsStatusDataSource = new MatTableDataSource<EventsStatusTableType>();
  closeEventData: CloseEventsType[] = [];
  closeEventDataTotalElements = null;
  closeEventDataPageIndex = 1;
  closeEventDataLastPageIndex = 1;
  closeEventDataSource = new MatTableDataSource<CloseEventsType>();
  notSelectedText = 'Не выбрано';
  countryText = CountryText;
  filterByKato = this.notSelectedText;
  filterByDateFrom: string | number | Date;
  filterByDateTo: string | number | Date;
  totalEvents = null;

  closeEventChartOptions: ChartOptions = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        ticks: {
          min: 0,
          stepSize: 50,
        }
      }],
    }
  }
  closeEventChartLabels = CloseRoadEvents;
  closeEventChartType: ChartType = 'horizontalBar';
  closeEventChartData: ChartDataSets[] = [
    {
      data: [0,0,0,0,0],
      label: 'Количество',
      backgroundColor: '#6ce4ec',
      barThickness: 'flex',
      barPercentage: 1,
    }
  ];

  eventStatusChartOptions: ChartOptions = {
    legend: {
      labels: {
        boxWidth: 12
      }
    }
  }
  eventStatusChartLabels = EventStatuses;
  eventStatusChartType: ChartType = 'pie';
  eventStatusChartData: ChartDataSets[] = [
    {
      data: [0,0,0],
      barThickness: 'flex',
    }
  ];

  @ViewChild('closeEventChart')
  private closeEventChartComponent: BaseChartDirective;
  @ViewChild('eventStatusChart')
  private eventStatusChartComponent: BaseChartDirective;

  constructor(
      private ntf: NotificationService,
      private adminService: AdminService,
      public kato: KatoService,
  ) {
  }

  ngOnInit() {
    this.kato.getKatoList();
    this.getEventsStatus();
    this.getCloseEvents();
  }

  onSelectDate(date: string, isDateFrom: boolean) {
    if(isDateFrom) {
      this.filterByDateFrom = date;
    } else {
      this.filterByDateTo = date;
    }
    this.getEventsStatus();
    this.getCloseEvents();
  }

  onSelectKato(kato: string) {
    this.filterByKato = kato;
    this.getEventsStatus();
    this.getCloseEvents();
  }

  removeFilterValues() {
    this.filterByKato = this.notSelectedText;
    this.filterByDateFrom = undefined;
    this.filterByDateTo = undefined;
    this.getEventsStatus();
    this.getCloseEvents();
  }

  getEventsStatus() {
    this.ntf.showLoader();
    const params = this.getParams();
    this.adminService.getEventsStatus(params).subscribe({
      next: (data: EventsStatusType[]) => {
        const newData = data.filter(item => item.kato !== KatoKazakhstan);
        const {tableData, chartData, total} = this.getEventStatusChartData(newData);
        this.totalEvents = total;
        this.eventStatusChartComponent.datasets[0].data = chartData;
        this.eventStatusChartComponent.update();
        this.eventsStatusData = tableData;
        const eventsStatusDataFiltered = tableData.filter(
            (_, i) => i < 10
        );
        this.eventsStatusDataTotalElements = tableData.length;
        this.eventsStatusDataLastPageIndex =
            tableData.length > 9
                ? Number(String(tableData.length).split('')[0]) + 1
                : 1;
        this.eventsStatusDataSource = new MatTableDataSource(eventsStatusDataFiltered);
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  getEventStatusChartData(data: EventsStatusType[]) {
    let createdCount = 0;
    let finishedCount = 0;
    let rejectedCount = 0;
    let total = 0;
    const tableData = data.map(status => {
      createdCount += status.createdCount;
      finishedCount += status.finishedCount;
      rejectedCount += status.rejectedCount;
      const statusTotal = status.createdCount + status.finishedCount + status.rejectedCount;
      total += statusTotal;
      return {...status, total: statusTotal};
    });
    let eventStatusData: EventsStatusType = {};
    if(this.filterByKato === this.notSelectedText) {
      const kazakhstan: EventsStatusTableType = {
        kato: CountryText,
        createdCount,
        finishedCount,
        rejectedCount,
        total,
      }
      tableData.unshift(kazakhstan);
      eventStatusData = kazakhstan;
    } else {
      eventStatusData = data[0];
    }
    const chartData: number[] = [
      eventStatusData?.rejectedCount,
      eventStatusData?.finishedCount,
      eventStatusData?.createdCount
    ];
    return {
      tableData,
      chartData,
      total,
    }
  }

  getCloseEvents() {
    this.ntf.showLoader();
    const params = this.getParams();
    this.adminService.getCloseEvents(params).subscribe({
      next: (data: CloseEventsType[]) => {
        const newData = data.filter(item => item.kato !== KatoKazakhstan);
        const {tableData, chartData} = this.getCloseEventChartData(newData);
        this.closeEventChartComponent.datasets[0].data = chartData;
        this.closeEventChartComponent.update();
        this.closeEventData = tableData;
        const closeEventDataFiltered = tableData.filter(
            (_, i) => i < 10
        );
        this.closeEventDataTotalElements = tableData.length;
        this.closeEventDataLastPageIndex = Math.floor(tableData.length / 10);
        this.closeEventDataSource = new MatTableDataSource(closeEventDataFiltered);
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  getCloseEventChartData(data: CloseEventsType[]) {
    let carAccidentCount = 0;
    let animalOnRoadCount = 0;
    let foreignObjectsOnRoadCount = 0;
    let backWeatherConditionsCount = 0;
    let emergencyStateOfTheRoadCount = 0;
    data.forEach(event => {
      carAccidentCount += event.carAccidentCount;
      animalOnRoadCount += event.animalOnRoadCount;
      foreignObjectsOnRoadCount += event.foreignObjectsOnRoadCount;
      backWeatherConditionsCount += event.backWeatherConditionsCount;
      emergencyStateOfTheRoadCount += event.emergencyStateOfTheRoadCount;
    })

    const tableData = data;
    let closeEventData: CloseEventsType = {};

    if (this.filterByKato === this.notSelectedText) {
      const kazakhstan: CloseEventsType = {
        kato: CountryText,
        carAccidentCount,
        animalOnRoadCount,
        foreignObjectsOnRoadCount,
        backWeatherConditionsCount,
        emergencyStateOfTheRoadCount,
      }
      tableData.unshift(kazakhstan);
      closeEventData = kazakhstan;
    } else {
      closeEventData = data[0];
    }
    const chartData = [
      closeEventData?.carAccidentCount,
      closeEventData?.animalOnRoadCount,
      closeEventData?.foreignObjectsOnRoadCount,
      closeEventData?.backWeatherConditionsCount,
      closeEventData?.emergencyStateOfTheRoadCount,
    ];
    return {
      chartData,
      tableData,
    }
  }

  getParams() {
    const kato = this.filterByKato !== this.notSelectedText ? this.filterByKato : '';
    const dateFrom = this.filterByDateFrom && new Date(this.filterByDateFrom).toISOString().slice(0, -8);
    const dateTo = this.filterByDateTo && new Date(this.filterByDateTo).toISOString().slice(0, -8);
    return {
      ...(kato && {kato}),
      ...(dateFrom && {dateFrom}),
      ...(dateTo && {dateTo}),
    };
  }

  paginationHandler(isEventStatusTable: boolean, paginationWay: PaginationWayTypes) {
    if(isEventStatusTable) {
      let pageIndex = this.eventsStatusDataPageIndex;
      if (paginationWay === PaginationWayTypes.START) {
        pageIndex = 1;
      } else if (paginationWay === PaginationWayTypes.PREV) {
        pageIndex -= 1;
      } else if (paginationWay === PaginationWayTypes.NEXT) {
        pageIndex += 1;
      } else if (paginationWay === PaginationWayTypes.LAST) {
        pageIndex = this.eventsStatusDataLastPageIndex;
      }
      const tableData = this.eventsStatusData.filter(
          (_, i) => i < pageIndex * 10 && i >= (pageIndex * 10) - 10
      );
      this.eventsStatusDataPageIndex = pageIndex;
      this.eventsStatusDataSource = new MatTableDataSource(tableData);
    } else {
      let pageIndex = this.closeEventDataPageIndex;
      if (paginationWay === PaginationWayTypes.START) {
        pageIndex = 1;
      } else if (paginationWay === PaginationWayTypes.PREV) {
        pageIndex -= 1;
      } else if (paginationWay === PaginationWayTypes.NEXT) {
        pageIndex += 1;
      } else if (paginationWay === PaginationWayTypes.LAST) {
        pageIndex = this.closeEventDataLastPageIndex;
      }
      const tableData = this.closeEventData.filter(
          (_, i) => i < pageIndex * 10 && i >= (pageIndex * 10) - 10
      );
      this.closeEventDataPageIndex = pageIndex;
      this.closeEventDataSource = new MatTableDataSource(tableData);
    }
  }

}
