import { Injectable } from '@angular/core';
import { Authenticate, Role, Token, User } from 'src/app/model/user';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiConfigUsers } from 'src/api.config';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User = new User();

  getUser(): User {
    return this.user;
  }

  isLoginDisable: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private ntf: NotificationService // private toastr: ToastrService
  ) { }

  signin(val: Authenticate) {
    const body = {
      "username": val.username,
      "password": val.password,
      "rememberMe": true
    }
    this.http
      .post(`${ApiConfigUsers.api}/api/authenticate`, body)
      .subscribe({
        next: (data: any) => {
          if (data.success == true) {
            this.authSuccess(data)
          } else {
            this.authError(data)
          }
        },
        error: (error) => {
          this.authError(error)
        },
      });
  }

  private authSuccess(data: any) {
    this.setAuthData(data.answer.id_token)
    this.ntf.showNotification('snackbar-success', 'Успешно авторизован');
    this.isLoginDisable = false;
    this.spinner.show();
    setTimeout(() => {
      this.router.navigate(['/users']);
      // this.spinner.hide();
    }, 500);
  }

  private authError(data: any) {
    this.spinner.hide();
    this.ntf.showNotification(
      'snackbar-danger',
      'Введен неверный логин или пароль'
    );
    this.isLoginDisable = false;
    console.error(data);
  }

  public setAuthData(token: string) {
    localStorage.setItem('access_token', token);
  }

  // getCurrentUser() {
  //   this.http.get(`${ApiConfigUsers.api}/user/current`).subscribe({
  //     next: (user: User) => {
  //       if (user) {
  //         this.user.setValue(user);
  //         console.log(this.user);
  //       }
  //     },
  //     error: (e) => {
  //       console.error(e);
  //     },
  //   });
  // }

  logout() {
    // localStorage.clear();
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
    this.router.navigate(['/authentication/signin']);
  }
}
