import { NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { WINDOW_PROVIDERS } from './core/service/window.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RefreshTokenInterceptor } from './authentication/service/TokenInterceptor';

import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { AppComponent } from './app.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { UsersComponent } from './components/users/users.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FilterComponent } from './components/filter/filter.component';
import { IdentifyComponent } from './components/identify/identify.component';
import { NewUserComponent } from './components/users/pages/new-user/new-user.component';
import { UserItemComponent } from './components/users/pages/user-item/user-item.component';
import { UserFormComponent } from './components/users/pages/user-form/user-form.component';
import { LoggingLogComponent } from './components/logging-log/logging-log.component';
import { TariffManagementComponent } from './components/tariff-management/tariff-management.component';
import { RsoCommentsComponent } from './components/rso-comments/rso-comments.component';
import { EventsComponent } from './components/events/events.component';
import { EventsReportComponent } from './components/events-report/events-report.component';
import { ChartsModule } from "ng2-charts";
import { CloseRoadReportComponent } from './components/close-road-report/close-road-report.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationCreateComponent } from './components/notifications/notification-create/notification-create.component';
import { EventChangeStatusComponent } from './components/events/event-change-status/event-change-status.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ClickOutsideModule,
    CoreModule,
    SharedModule,
    ChartsModule,
  ],
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AppComponent,
    PageLoaderComponent,
    AuthLayoutComponent,
    UsersComponent,
    IdentifyComponent,
    FilterComponent,
    NewUserComponent,
    UserItemComponent,
    UserFormComponent,
    LoggingLogComponent,
    TariffManagementComponent,
    RsoCommentsComponent,
    EventsComponent,
    EventsReportComponent,
    CloseRoadReportComponent,
    NotificationsComponent,
    NotificationCreateComponent,
    EventChangeStatusComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    WINDOW_PROVIDERS,
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
