export interface EventsStatusType {
    kato?: string;
    createdCount?: number;
    finishedCount?: number;
    rejectedCount?: number;
}

export interface EventsStatusTableType extends EventsStatusType {
    total: number;
}

export interface CloseEventsType {
    kato?: string;
    carAccidentCount?: number;
    animalOnRoadCount?: number;
    foreignObjectsOnRoadCount?: number;
    backWeatherConditionsCount?: number;
    emergencyStateOfTheRoadCount?: number;
}

export const CloseRoadEvents = [
    'ДТП',
    'Выход животного',
    'Посторонние предметы',
    'Погодные условия',
    'Аварийное состояние',
]
export const EventStatuses = [
    'Отклонено',
    'Исполнено',
    'В работе',
]

export const KatoKazakhstan = '000000000';
export const CountryText = 'Страна';

export enum PaginationWayTypes {
    START = 'start',
    PREV = 'prev',
    NEXT = 'next',
    LAST = 'last',
}
