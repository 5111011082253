import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GeoportalAdminService } from 'src/app/services/geoportal-admin.service';
import { Attribute, Layer } from 'src/app/shared/interfaces';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.sass'],
})
export class FilterComponent implements OnInit {
  displayedColumns: string[] = [
    'nameRu',
    'nameKz',
    'sysname',
    'search',
    'searchResultRu',
    'searchResultKz',
  ];

  displayedLayerColumns: string[] = [
    'id',
    'nameRu',
    'nameKz',
    'layerName',
    'index',
  ];

  public form: FormGroup;

  layerNames: Layer[] = [];
  data: Attribute[] = [];
  layers: Layer[] = [];
  dataSource: any = new MatTableDataSource(this.data);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSourceLayer: any = new MatTableDataSource(this.data);
  @ViewChild(MatPaginator) paginatorLayer!: MatPaginator;
  @ViewChild(MatSort) sortLayer!: MatSort;

  selectedLayer: Layer;

  constructor(
    private ntf: NotificationService,
    private geoportalAdminService: GeoportalAdminService
  ) {
    this.form = this.initForm();
  }

  ngOnInit(): void {
    this.getLayers();
  }

  initForm() {
    return new FormGroup({
      layerName: new FormControl(null),
    });
  }

  selectionLayer(event: any) {
    if (event && event.value) {
      this.selectedLayer = event.value;
      this.getAttrs(event.value?.id)
    } else {
      this.selectedLayer = null;
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  changeSearchAttr(element: Attribute, search: boolean, value: string) {
    if (!element) return;
    this.ntf.showLoader();
    this.geoportalAdminService.editAttrsByLayerId(this.selectedLayer.id, element.id, { ...element, [value]: search }).subscribe({
      next: () => {
        this.ntf.hideLoader();
        this.getAttrs(this.selectedLayer?.id)
        this.ntf.showNotification('snackbar-success', 'Успешно изменен');
      },
      error: (err) => {
        console.error(err);
        this.ntf.hideLoader();
      },
    })
  }

  index(element: Layer) {
    console.log(element)
    if (!element) return;
    this.ntf.showLoader();
    this.geoportalAdminService.globalSearchIndex({
      layerName: element.layerName
    }).subscribe({
      next: () => {
        this.ntf.showNotification('snackbar-success', 'Операция прошла успешно');
        this.ntf.hideLoader();
      },
      error: (err) => {
        console.error(err);
        this.ntf.hideLoader();
      }
    })
  }

  getLayers() {
    this.ntf.showLoader();
    this.geoportalAdminService.getLayers().subscribe({
      next: (layers: Layer[]) => {
        this.ntf.hideLoader();
        this.layerNames = layers.filter(el => el.layerName);
        this.layers = layers;
        this.dataSourceLayer = new MatTableDataSource(this.layers.filter(el => el.layerName));
        this.dataSource.sortLayer = this.sortLayer;
        this.dataSource.paginatorLayer = this.paginatorLayer;
      },
      error: (err) => {
        console.error(err);
        this.ntf.hideLoader();
      },
    })
  }

  getAttrs(layerId: number) {
    if (!layerId) return;
    this.ntf.showLoader();
    this.geoportalAdminService.getAttrsByLayerId(layerId).subscribe({
      next: (res: Attribute[]) => {
        this.ntf.hideLoader();
        this.data = res.sort((a, b) => a.id - b.id);
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      error: (err) => {
        console.error(err);
        this.ntf.hideLoader();
      },
    })
  }
}
